import {MouseEvent} from 'react'
import {useAskExpertApi} from '../../../../../apiHooks';

export const useAskExpertDeleteModalViewModel = (questionId: string, closeModalHandler?: () => void) => {
  const {deleteQuestion} = useAskExpertApi(closeModalHandler);

  const confirmHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await deleteQuestion(questionId);
  }

  return {
    confirmHandler
  }
}
