import { ChangeEvent, MouseEvent } from 'react'
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pl } from 'date-fns/locale';
import React from "react";
import { GenericIcon } from "../../../../shared/genericIcon/GenericIcon";

const options = [
  {value: 'obecny miesiąc', label: 'obecny miesiąc'},
  {value: 'poprzedni miesiąc', label: 'poprzedni miesiąc'},
  {value: 'zakres dat', label: 'zakres dat'},
];

interface IProps {
  selectedValue: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  generatePDF: (event: MouseEvent<HTMLButtonElement>) => void
  handleEndDate: (date: Date | null) => void
  handleStartDate: (date: Date | null) => void
  startDateValue: Date | null
  endDateValue: Date | null
}

export const RadioButtonsGroup: React.FC<IProps> = ({
                                                      generatePDF,
                                                      selectedValue,
                                                      handleChange,
                                                      startDateValue,
                                                      endDateValue,
                                                      handleStartDate,
                                                      handleEndDate
                                                    }) => {


  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <Grid alignItems={'center'} flexDirection={'row'} display={'flex'}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  checked={selectedValue === option.value}
                  onChange={handleChange}
                />
              }
              label={option.label}
            />
          ))}
          <Grid mb={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker slotProps={{textField: {size: 'small'}}} label="Od" value={startDateValue}
                            disabled={!(selectedValue === 'zakres dat')}
                            onChange={(val: Date | null) => handleStartDate(val)}/>
                <DatePicker slotProps={{textField: {size: 'small'}}} label="Do" value={endDateValue}
                            disabled={!(selectedValue === 'zakres dat')}
                            onChange={(val: Date | null) => handleEndDate(val)}/>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid ml={2}>
            <Button
              variant='contained'
              onClick={(e) => generatePDF(e)}
              type='button'
              size={'medium'}
            >
              <GenericIcon iconName='PictureAsPdf'/>
              <Typography variant='body1' sx={{ml: '12px', fontWeight: '500'}}>
                GENERUJ PDF
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  )
}