import { makeAutoObservable } from "mobx";
import { ISubmissionsReport, ReportsInfo } from "../interfaces/reports/reportsInfo";

export class ReportsStore {
  reports: ReportsInfo[] = [];
  submissionsReport: ISubmissionsReport | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setReports(reports: ReportsInfo[]) {
    this.reports = reports
  }

  get reportsDrawArray() {
    return this.reports
  }

  setSubmissionsReport(submissionsReport: ISubmissionsReport | null) {
    this.submissionsReport = submissionsReport
  }
}