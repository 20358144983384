import { useReportsViewModel } from "./useReports.vm";
import { useModalSettings } from "../../shared/modals/modalSettingsHook/useModalSettings";
import { Grid } from "@mui/material";
import { CustomTable } from "../../shared/customTables/customTable/CustomTable";

import { observer } from "mobx-react-lite";
import React from "react";
import { ReportsModal } from "./reportsModal/ReportsModal";
import { AuditOnlineModal } from "./auditOnlineModal/AuditOnlineModal";

const Reports = () => {

	const {
		tableDataRows,
		isDataDownloaded,
		headCells,
		uploadActionHandler,
		getSelectedCheckboxes,
		selectedCheckboxes,
		generatePDFButtonHandler
	} = useReportsViewModel();

	const {
		id: reportId,
		addModalHandler,
		viewModalHandler,
		isModalOpen,
		closeModalHandler
	} = useModalSettings();

	const {
		addModalHandler: addAuditOnlineModalHandler,
		isModalOpen: isAuditOnlineModalOpen,
		closeModalHandler: closeAuditOnlineModalHandler
	} = useModalSettings();

	return (
		<Grid
			container
			width='100%'
			maxWidth='100%'
			height='100%'
			maxHeight='100%'
			flexDirection='column'
		>
			<CustomTable
				isGeneratePDFButtonEnabled={true}
				isCheckboxEnabled={true}
				data={tableDataRows}
				isDataDownloaded={isDataDownloaded}
				headCells={headCells}
				addModalHandler={addModalHandler}
				isActionsEnabled={true}
				viewActionHandler={viewModalHandler}
				isAdditionalCellNeeded={true}
				isUploadActionEnabled={true}
				uploadActionHandler={uploadActionHandler}
				getSelectedCheckboxes={getSelectedCheckboxes}
				selectedCheckboxes={selectedCheckboxes}
				generatePDFButtonHandler={generatePDFButtonHandler}
				isAuditOnlineEnabled={true}
				openAuditOnlineModalHandler={addAuditOnlineModalHandler}
			/>
			{
				isModalOpen && <ReportsModal
					closeModalHandler={closeModalHandler}
					reportId={reportId}
					isModalOpen={isModalOpen}
				/>
			}
			{
				isAuditOnlineModalOpen && <AuditOnlineModal
					isModalOpen={isAuditOnlineModalOpen}
					closeModalHandler={closeAuditOnlineModalHandler}
				/>
			}
		</Grid>
	)
}

export default observer(Reports);