import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { ProjectUserRoles } from "../../../../constants";
import { GenericIcon, IconNames } from "../../genericIcon/GenericIcon";
import { useAuthStore } from "../../../../store/hooks";

interface IProps {
	name: IconNames;
	params?: string;
	onClick?: () => void;
	actionAccess?: ProjectUserRoles[];
}

export const ActionButton: FC<IProps> = observer(({name, onClick, params, actionAccess}) => {
	const authStore = useAuthStore();
	const {role} = authStore;

	if (actionAccess && !actionAccess.includes(role)) return null;

	return (
		<button
			style={{background: 'none', border: 'none', outline: 'none', cursor: 'pointer'}}
			onClick={() => onClick && onClick()}
		>
			<GenericIcon iconName={name} style={{color: 'secondary.dark'}}/>
		</button>
	)
})