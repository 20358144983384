import { useEffect, useMemo, useState } from "react";
import type { Swiper } from 'swiper';
import { useAuditOnsiteApi } from "../../../../../../../apiHooks";
import { useAuditOnsiteStore } from "../../../../../../../store/hooks";

export const useImageSwiperViewModel = (commentId: number) => {
  const {getAuditImages} = useAuditOnsiteApi()
  const bodyOverflow: HTMLBodyElement | null = useMemo(() => document.querySelector('body'), []);
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);
  const auditOnSiteStore = useAuditOnsiteStore()
  const {auditImages} = auditOnSiteStore

  useEffect(() => {
    if (bodyOverflow) {
      bodyOverflow.style.overflow = 'hidden';
    }

    return () => {
      if (bodyOverflow) {
        bodyOverflow.style.overflow = 'auto';
      }
    };
  }, [bodyOverflow, auditOnSiteStore]);

  useEffect(() => {
    if (commentId) {
      void getAuditImages(commentId)
    }

    return () => {
      auditOnSiteStore.clearAuditImages()
    }

  }, [commentId, getAuditImages, auditOnSiteStore])

  return {
    thumbsSwiper,
    setThumbsSwiper,
    auditImages
  }

}