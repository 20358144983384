import React, { FC } from "react";
import { Grid } from "@mui/material";
import { IAuditOnsiteFieldInfo } from "../../../../../../interfaces";
import { LabelItem } from "../labelItem/LabelItem";
import { getSingleLabelArrayHandler } from "../../../helpers/getSingleLabelArrayHandler";

interface IProps {
  groupArray: IAuditOnsiteFieldInfo[]
}

export const LabelList: FC<IProps> = ({
                                        groupArray
                                      }): JSX.Element => {

  const labelsTitleArray: string[] = [...new Set(groupArray.map(el => el.label))]

  return (
    <Grid>
      {
        labelsTitleArray.map((el, index) => (
          <LabelItem key={index} labelName={el} labelArray={getSingleLabelArrayHandler(groupArray, el)}/>
        ))
      }
    </Grid>
  )
}