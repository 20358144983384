import React, { FC } from "react";
import { IAuditOnsiteFieldInfo } from "../../../../../../interfaces";
import { GroupList } from "../../group/groupList/GroupList";
import { getMaxCountValueHandler } from "../../helpers/getMaxCountValueHandler";

import '../../../scss/singleCategory.scss'

interface IProps {
  categoryName: string;
  categoriesCount: number;
  categoryIndex: number;
  categoryArray: IAuditOnsiteFieldInfo[];
}

export const CategoryItem: FC<IProps> = ({
                                           categoryName,
                                           categoriesCount,
                                           categoryIndex,
                                           categoryArray
                                         }): JSX.Element => {

  return (
    <details className='categoryItem' open>
      <summary className='categoryItem--title'>
        {categoryIndex + 1}/{categoriesCount} {categoryName}
        <span className='categoryItem--count'>
        (MAX {getMaxCountValueHandler(categoryArray)} PKT.)
        </span>
      </summary>
      <main className='categoryItem--content'>
        <GroupList
          categoryArray={categoryArray}
        />
      </main>
    </details>
  )
}