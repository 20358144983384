import { makeAutoObservable } from "mobx";
import { IChecklistSet, IEmployeeInfo } from "../interfaces";

export class EmployeesStore {
  employees: IEmployeeInfo[] = [];
  availableChecklists: IChecklistSet[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setEmployees(employees: IEmployeeInfo[]) {
    this.employees = employees
  }


  addEmployee(employee: IEmployeeInfo) {
    this.employees.push(employee)
  }

  setAvailableChecklists(checklists: IChecklistSet[]) {
    this.availableChecklists = checklists
  }

  get employeesDrawArray() {
    return this.employees
  }
}