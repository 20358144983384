import React, { FC } from "react";
import { IAuditOnsiteFieldInfo } from "../../../../../../interfaces";
import { LabelList } from "../../label/labelList/LabelList";

import { getMaxCountValueHandler } from "../../helpers/getMaxCountValueHandler";
import { getCountValueHandler } from "../../helpers/getCountValueHandler";

import '../../../scss/singleGroup.scss';

interface IProps {
  groupName: string;
  groupArray: IAuditOnsiteFieldInfo[]
}

export const GroupItem: FC<IProps> = ({
                                        groupArray,
                                        groupName
                                      }): JSX.Element => {


  return (
    <details className='groupItem'>
      <summary className='groupItem--title'>
        {groupName}
        <span className='groupItem--count'>
          ({getCountValueHandler(groupArray)}/{getMaxCountValueHandler(groupArray)} PKT.)
        </span>
      </summary>
      <main className='groupItem--content'>
        <LabelList
          groupArray={groupArray}
        />
      </main>
    </details>
  )
}