import { useRootStore } from "../RootStateContext";

export const useNewsletterStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.newsletterStore) {
    throw new Error('newsletterStore store should be defined');
  }

  return rootStore.newsletterStore;
}