import {useApiCall} from "./apiCall/useApiCall";
import {useNewsletterStore, useNotificationsStore} from "../store/hooks";
import {useCallback} from "react";
import {NotificationType} from "../store/notificationsStore/notificationType";
import {NewsletterInfo} from "../interfaces/newsletter/newsletterInfo";

export const useNewsletterApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const newsletterStore = useNewsletterStore();
  const notificationsStore = useNotificationsStore();

  const getNewsletter = useCallback(async () => {
      const res = await api.apiInstance.get('newsletters', true);
      if (res) {
        newsletterStore.setNewsletterList(res.list)
        newsletterStore.setNewsletterClientList(res.recipients)
      }
    },
    [api.apiInstance, newsletterStore]
  );

  const saveNewsletter = useCallback(async (data: any, id?: string) => {
    let res: NewsletterInfo
    if (id) {
      res = await api.apiInstance.put(`newsletters/${id}`, data, true);
    } else {
      res = await api.apiInstance.post('newsletters', data, true);
    }

    if (res) {
      if (id) {
        const currentNewsletters = newsletterStore.newsletterList!.map(el => el.id === id ? res : el);
        newsletterStore.setNewsletterList(currentNewsletters)
      } else {
        newsletterStore.setNewsletterList([...newsletterStore.newsletterList, res])
      }

      notificationsStore.setNotification({message: 'Newsletter został zapisany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    }
  }, [api.apiInstance, closeModalHandler, newsletterStore, notificationsStore])

  const sendNewsletter = useCallback(async (data: any, id?: string) => {
    let res: NewsletterInfo
    if (id) {
      res = await api.apiInstance.put(`newsletters/${id}/send`, data, true);
    } else {
      res = await api.apiInstance.post('newsletters/send', data, true);
    }

    if (res) {
      if (id) {
        const currentNewsletters = newsletterStore.newsletterList!.map(el => el.id === id ? res : el);
        newsletterStore.setNewsletterList(currentNewsletters)
      } else {
        newsletterStore.setNewsletterList([...newsletterStore.newsletterList, res])
      }
      notificationsStore.setNotification({message: 'Newsletter został wysłany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    }
  }, [api.apiInstance, closeModalHandler, newsletterStore, notificationsStore])


  const deleteNewsletter = useCallback(async (id: string) => {
    const res = await api.apiInstance.delete(`newsletters/${id}`, true);
    if (res) {
      const currentNewsletterList = newsletterStore.newsletterList.filter(el => el.id !== id)
      newsletterStore.setNewsletterList(currentNewsletterList)
      notificationsStore.setNotification({message: 'Newsletter został usunięty.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    }

  }, [api.apiInstance, newsletterStore, closeModalHandler, notificationsStore])

  return {
    getNewsletter,
    saveNewsletter,
    sendNewsletter,
    deleteNewsletter
  }
}
