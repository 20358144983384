import React from "react";
import { Grid, Typography } from "@mui/material"

import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logo from '../../../../assets/images/logo/logo_gray-scale.svg'

interface IProps {
  isNavbarOpen: boolean
  toggleNavBarHandler: () => void
  title?: string
}

export const NavBarHeader: React.FC<IProps> = ({title, isNavbarOpen, toggleNavBarHandler}) => (
  <Grid p={1} mb={3}>
    <Grid
      container
      justifyContent='flex-end'
    >
      <button
        onClick={toggleNavBarHandler}
        style={{
          background: '#BDBDBD',
          border: 'none',
          borderRadius: '50%',
          padding: '4px',
          outline: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        {
          isNavbarOpen
            ?
            <CloseIcon sx={{color: 'secondary.main'}} fontSize='small'/>
            :
            <ArrowForwardIcon sx={{color: 'secondary.main'}} fontSize='small'/>
        }
      </button>
    </Grid>

    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
        <a href={'/'} >
            <img
                src={logo}
                alt="logo"
                style={{
                    width: '180px',
                    height: '70px',
                    marginLeft: isNavbarOpen ? '0' : '-300px',
                    transition: 'margin-left 0.3s ease-in-out'
                }}
            />
        </a>
    </Grid>
    {
      title && (
        <Typography
          variant='h6'
          color='white'
          mt={3}
          style={{
            marginLeft: isNavbarOpen ? '0' : '-300px',
            transition: 'margin-left 0.3s ease-in-out'
          }}
        >
          {title}
        </Typography>
      )
    }
  </Grid>
)