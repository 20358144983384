import { useCallback } from "react";
import { useCurrentClientStore, useNotificationsStore, useReportsStore } from "../store/hooks";
import { useApiCall } from "./apiCall/useApiCall";
import { useParams } from "react-router-dom";
import { NotificationType } from "../store/notificationsStore/notificationType";
import { fileDownload } from "../utils/fileDownload";


export const useReportsApi = () => {
  const {api} = useApiCall();
  const reportsStore = useReportsStore()
  const currentClientStore = useCurrentClientStore();
  const notificationsStore = useNotificationsStore();
  const params = useParams()
  const {clientId} = params

  const getReports = useCallback(async () => {
      const res = await api.apiInstance.get(clientId ? `client/${clientId}/checklists-for-report` : 'checklists-for-report', true);

      if (res) {
        reportsStore.setReports(res.list)
        currentClientStore.setClientName(res.clientName, clientId);
      }
    },
    [api.apiInstance, clientId, currentClientStore, reportsStore]
  );

  const getSubmissionsReport = useCallback(async (id: string, startDate: string, endDate: string) => {
    const res = await api.apiInstance.get(clientId ? `client/${clientId}/checklists/${id}/report?startDate=${startDate}&endDate=${endDate}` : `checklists/${id}/report?startDate=${startDate}&endDate=${endDate}`, true)

    if (res) {
      reportsStore.setSubmissionsReport(res)
    }
  }, [api.apiInstance, clientId, reportsStore])

  const uploadPdfReport = useCallback(async (id: string, startDate?: string, endDate?: string, isZip: boolean = false) => {
    const res = await api.apiInstance.get(clientId ? `/client/${clientId}/checklists/report-pdfs?ids=${id}${startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''}` : `checklists/report-pdfs?ids=${id}${startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''}`, true, {responseType: 'blob'})

    if (res) {
      fileDownload(res, isZip)
      notificationsStore.setNotification({message: 'Dokument został pobrany.', type: NotificationType.SUCCESS})
    }
  }, [api.apiInstance, clientId, notificationsStore])

  const approveSubmissionInReport = useCallback(async (id: string, startDate: string, endDate: string) => {
    if (clientId) {
      const res = await api.apiInstance.patch(`client/${clientId}/checklists/${id}/approve?startDate=${startDate}&endDate=${endDate}`, {}, true)
      if (res) {
        reportsStore.setSubmissionsReport(res)
      }
    }
  }, [api.apiInstance, clientId, reportsStore])

  return {
    getReports,
    uploadPdfReport,
    getSubmissionsReport,
    approveSubmissionInReport
  }
}