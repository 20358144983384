import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useAuthStore, useReportsStore } from "../../../../store/hooks";
import { defined } from "../../../../utils/type-checks";
import { useReportsApi } from "../../../../apiHooks";
import { countDates, formatDate } from "./helpers";
import { ProjectUserRoles } from "../../../../constants";

export const useReportsModalViewModel = (reportId?: string, closeModalHandler?: () => void) => {
  const reportsStore = useReportsStore();
  const {getSubmissionsReport, approveSubmissionInReport, uploadPdfReport} = useReportsApi()
  const authStore = useAuthStore()
  const {role} = authStore
  const [selectedValue, setSelectedValue] = useState('obecny miesiąc');
  const [startDateValue, setStartDateValue] = useState<Date | null>(null);
  const [endDateValue, setEndDateValue] = useState<Date | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDateValue(date)
  }
  const handleEndDate = (date: Date | null) => {
    setEndDateValue(date)
  }
  const reportInfo = reportId ? reportsStore.reports.find((report) => defined(report.id) === reportId) : undefined;

  const handleDates = useCallback((endpoint: (id: string, startDate: string, endDate: string) => Promise<void>) => {
    if (selectedValue === 'zakres dat') {
      if (startDateValue && endDateValue) {
        void endpoint(reportId!, formatDate(startDateValue), formatDate(endDateValue))
      }
    } else {
      const dates = countDates(selectedValue)
      void endpoint(reportId!, dates.firstDayFormatted, dates.lastDayFormatted!)
    }
  }, [endDateValue, reportId, selectedValue, startDateValue])

  const generatePDF = (e: MouseEvent<HTMLButtonElement>) => {
    handleDates(uploadPdfReport)
  }

  const confirmHandler = (e: MouseEvent<HTMLButtonElement>) => {
    handleDates(approveSubmissionInReport)
  }

  const isAuditor = ProjectUserRoles.ROLE_AUDITOR === role

  useEffect(() => {
    handleDates(getSubmissionsReport)
  }, [endDateValue, getSubmissionsReport, handleDates, reportId, selectedValue, startDateValue])

  return {
    confirmHandler,
    reportInfo,
    selectedValue,
    handleChange,
    startDateValue,
    endDateValue,
    handleStartDate,
    handleEndDate,
    isAuditor,
    generatePDF
  }
}