import { ITableData } from "../../../../interfaces";

export const stableSort = <T>(array: ITableData[], comparator: (a: T, b: T) => number, isOrderDate: boolean) => {

  if (isOrderDate) {

    const arrayWithTimestamp = changeStringToTimestamp(array) as ITableData[]

    const stabilizedThis = arrayWithTimestamp.map((el, index) => [el, index] as [T, number]);

    const stabilazedAndSorted = stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order

      return a[1] - b[1];
    });

    return changeTimestampToString(stabilazedAndSorted.map((el) => el[0]));

  } else {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }
}

function changeStringToTimestamp(array: ITableData[]) {
  return array.map((el) => {
    const tempEl = { ...el }

    if (el.createdAt && typeof el.createdAt === 'string') {
      tempEl.createdAt = getTimestamp(el.createdAt)
    }

    if (el.updatedAt && typeof el.updatedAt === 'string') {
      tempEl.updatedAt = getTimestamp(el.updatedAt)
    }

    return tempEl
  })
}

function changeTimestampToString(array: any[]) {
  return array.map((el) => {
    const tempEl = { ...el }

    if (el.createdAt && typeof el.createdAt === 'number') {
      tempEl.createdAt = getDateString(el.createdAt)
    }

    if (el.updatedAt && typeof el.updatedAt === 'number') {
      tempEl.updatedAt = getDateString(el.updatedAt)
    }

    return tempEl
  })

}

function getDateString(timestamp: number): string {

  const tempLastUpdateDate = new Date(timestamp);
  const day = tempLastUpdateDate.getDate() < 10 ? `0${tempLastUpdateDate.getDate()}` : tempLastUpdateDate.getDate();
  const month = tempLastUpdateDate.getMonth() + 1 < 10 ? `0${tempLastUpdateDate.getMonth() + 1}` : tempLastUpdateDate.getMonth() + 1;
  const year = tempLastUpdateDate.getFullYear();

  return `${day}-${month}-${year}`;
}

function getTimestamp(dateString: string): number {

  const parts = dateString.split("-");
  const date = new Date(+parts[2], +parts[1] - 1, +parts[0]); // Note: months are 0-based

  return date.getTime();
}