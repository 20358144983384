import React, { ChangeEvent, FC } from "react";
import { Grid, TableCell } from "@mui/material";

interface IProps {
  dataId: string | number;
  isSelected: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CheckBoxCell: FC<IProps> = ({dataId, isSelected, onChange}): JSX.Element => {
  return (
    <TableCell
      sx={{
        padding: '8px 0 8px 8px',
        width: '25px',
      }}
    >
      <Grid
        container
        component='label'
        htmlFor={dataId as string}
        alignItems='center'
        justifyContent='center'
        maxWidth='25px'
      >
        <input
          style={{
            cursor: 'pointer',
          }}
          id={dataId as string}
          type='checkbox'
          name='checkbox'
          checked={isSelected}
          onChange={onChange}

        />
      </Grid>
    </TableCell>
  )
}