import React, { FC } from "react";
import { GenericIcon } from "../../../../../../shared/genericIcon/GenericIcon";

interface IProps {
  labelValue: 'yes' | 'no' | 'n/a';
}

export const FlagSwitcher: FC<IProps> = ({labelValue}): JSX.Element | null => {
  switch (labelValue) {
    case 'yes':
      return <GenericIcon iconName='CheckCircle' style={{color: '#2E7D32', marginRight: '8px'}}/>
    case 'no':
      return <GenericIcon iconName='RemoveCircle' style={{color: '#D32F2F', marginRight: '8px'}}/>
    case 'n/a':
      return <GenericIcon iconName='FlagCircle' style={{color: '#ED6C02', marginRight: '8px'}}/>
    default:
      return null
  }
}