import { useCallback } from "react";
import { useApiCall } from "./apiCall/useApiCall";
import { useParams } from "react-router-dom";
import { IAuditOnlineBody } from "../interfaces";
import { useNotificationsStore } from "../store/hooks";
import { NotificationType } from "../store/notificationsStore/notificationType";


export const useAuditOnlineApi = () => {
	const { api } = useApiCall();
	const params = useParams();
	const { clientId } = params;
	const notificationStore = useNotificationsStore();

	const getPDF = useCallback(async (body: IAuditOnlineBody) => {

		const res = await api.apiInstance.post(
			`/client/${clientId}/checklists/report-summary-pdfs`,
			body,
			true,
			{ responseType: 'blob' }
		);

		if (!res) {
			notificationStore.setNotification({ message: 'Brak audytów w podanych datach.', type: NotificationType.ERROR })
			return;
		}

		if (res) {

			const blob = new Blob(
				[res],
				{
					type: 'application/pdf',
				});
			const fileURL = URL.createObjectURL(blob);
			window.open(fileURL);
		}
	}, [api.apiInstance, clientId, notificationStore])

	return {
		getPDF
	}
}