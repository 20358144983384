import {ILink} from "../../../../../interfaces";

export const additionalAuditorLinks = (clientId: string): ILink[] => [
  {
    title: 'Biblioteka',
    link: `client/${clientId}/library`,
    ico: 'MenuBookRounded'
  },
  {
    title: 'Audyty onsite',
    link: `client/${clientId}/audits-onsite`,
    ico: 'PinDropRounded',
  },
  {
    title: 'Action list',
    link: `client/${clientId}/action-list`,
    ico: 'PlaylistAddCheckCircleRounded'
  },
  {
    title: 'Kontrola i zapisy',
    link: `client/${clientId}/reports`,
    ico: 'QueryStatsRounded'
  },
  {
    title: 'Wybór klienta',
    link: '/clients',
    ico: 'GroupsRounded'
  }
]