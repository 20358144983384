import { useCallback } from "react";
import { useAuthStore, useClientsStore, useNotificationsStore } from "../store/hooks";
import { useApiCall } from "./apiCall/useApiCall";
import { IClientInfo } from "../interfaces";
import { ProjectModalTypes, ProjectUserRoles } from "../constants";
import { NotificationType } from "../store/notificationsStore/notificationType";

export const useClientsApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const authStore = useAuthStore()
  const {role} = authStore
  const clientsStore = useClientsStore();
  const {clients} = clientsStore
  const notificationsStore = useNotificationsStore();
  const isAuditor = ProjectUserRoles.ROLE_AUDITOR === role

  const getClients = useCallback(async () => {
      const res: IClientInfo[] = await api.apiInstance.get(isAuditor ? 'users/auditor/clients' : 'users/clients', true);

      if (res) {
        clientsStore.setClients(res)
      }
    },
    [api.apiInstance, clientsStore, isAuditor]
  );

  const addClient = useCallback(async (data: FormData) => {
    const res = await api.apiInstance.post('users/client', data, true);
    if (res) {
      clientsStore.setClients([...clients, res]);
      notificationsStore.setNotification({message: 'Klient został dodany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    } else {
      clientsStore.setClients(clients);
    }

  }, [api.apiInstance, clients, clientsStore, closeModalHandler, notificationsStore])

  const editClient = useCallback(async (data: FormData, id?: string) => {
    const res = await api.apiInstance.post(`users/client/${id}`, data, true);
    if (res) {
      const currentClients = clients.map(el => el.uId === id ? res : el);
      clientsStore.setClients(currentClients);
      notificationsStore.setNotification({message: 'Zmiany zostały zapisane.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, clients, clientsStore, closeModalHandler, notificationsStore])

  const deleteClient = useCallback(async (id?: string) => {
    const res = await api.apiInstance.delete(`users/client/${id}`, true);
    if (res) {
      const currentClients = clients!.filter(el => el.uId !== id);
      clientsStore.setClients(currentClients);
      notificationsStore.setNotification({message: 'Klient został usunięty.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, clients, clientsStore, closeModalHandler, notificationsStore])

  const switchSubmitHandler = useCallback(async (modalType: string, formData: any, clientId?: string) => {
    switch (modalType) {
      case ProjectModalTypes.MODAL_TYPE_ADD:
        await addClient(formData);
        break;
      case ProjectModalTypes.MODAL_TYPE_EDIT:
        await editClient(formData, clientId);
        break;
      case ProjectModalTypes.MODAL_TYPE_VIEW:
        await editClient(formData, clientId);
        break;
      case ProjectModalTypes.MODAL_TYPE_DELETE:
        await deleteClient(clientId);
        break;
    }
  }, [addClient, editClient, deleteClient])

  return {
    getClients,
    switchSubmitHandler
  }
}