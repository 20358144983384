import {makeAutoObservable} from "mobx";
import {IAuditImages, IAuditOnsiteInfo, ISingleAuditOnsiteInfo} from "../interfaces";

export class AuditOnsiteStore {
  auditsOnsite: IAuditOnsiteInfo[] = [];
  auditOnSite: ISingleAuditOnsiteInfo | null = null;
  auditImages: IAuditImages[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setAuditsOnsite(auditsOnsite: IAuditOnsiteInfo[]) {
    this.auditsOnsite = auditsOnsite
  }

  setAuditOnSite(auditOnSite: ISingleAuditOnsiteInfo) {
    this.auditOnSite = auditOnSite
  }

  setAuditImages(auditImages: IAuditImages[]) {
    this.auditImages = auditImages
  }

  clearAuditOnSite() {
    this.auditOnSite = null
  }

  clearAuditImages() {
    this.auditImages = []
  }

  get auditsOnsiteDrawArray() {
    return this.auditsOnsite
  }

}