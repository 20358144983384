import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Page404 } from "../../../pages/page404/Page404";
import { GlobalLoader } from "../../../shared/loaders/globalLoader/GlobalLoader";

const Login = lazy(() => import('../../../pages/auth/login/Login'));
const ResetPassword = lazy(() => import('../../../pages/auth/resetPassword/ResetPassword'));
const ChangePassword = lazy(() => import('../../../pages/auth/changePassword/ChangePassword'));
const Home = lazy(() => import('../../../pages/home/Home'));
const PrivacyPolicy = lazy(() => import('../../../pages/privacyPolicy/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('../../../pages/termsOfUse/TermsOfUse'));

export const RoutesGuest = () => {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<GlobalLoader/>}>
            <Home/>
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<GlobalLoader/>}>
            <Login/>
          </Suspense>
        }
      />
      <Route
        path="/reset-password"
        element={
          <Suspense fallback={<GlobalLoader/>}>
            <ResetPassword/>
          </Suspense>
        }
      />
      <Route
        path="/change-password/:token"
        element={
          <Suspense fallback={<GlobalLoader/>}>
            <ChangePassword/>
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<GlobalLoader/>}>
            <PrivacyPolicy/>
          </Suspense>
        }
      />
      <Route
        path="/terms-of-use"
        element={
          <Suspense fallback={<GlobalLoader/>}>
            <TermsOfUse/>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={<Page404/>}
      />
    </Routes>
  )
}