import React, { FC } from "react";
import { ModalContainer } from "../../../shared/modals/modalContainer/ModalContainer";
import { CustomButtons } from "./CustomButtons";
import { useAuditOnlineModalViewModel } from "./useAuditOnlineModal.vm";
import { DateSection } from "./DateSection";
import { Divider } from "@mui/material";
import { CustomRadioGroup } from "./CustomRadioGroup";


interface IProps {
	isModalOpen: boolean;
	closeModalHandler: () => void;
}

export const AuditOnlineModal: FC<IProps> = ({isModalOpen, closeModalHandler}): JSX.Element => {
	
	const {
		acceptButtonHandler,
		rangeStart,
		rangeEnd,
		setStartRangeHandler,
		setEndRangeHandler,
		isModalError,
		completeness,
		setCompletenessHandler,
		completenessText,
		setCompletenessTextHandler,
		incidents,
		setIncidentsHandler,
		corrections,
		setCorrectionsHandler,
		correctionsText,
		setCorrectionsTextHandler,
		records,
		setRecordsHandler,
	} = useAuditOnlineModalViewModel();

	return (
		<ModalContainer
			backDrop={true}
			handleClose={closeModalHandler}
			isModalOpen={isModalOpen}
			title={'Raport audytu online'}
			style={{
				width: '1000px',
				maxWidth: '80%',
			}}
			customButtons={
				<CustomButtons
					closeModalHandler={closeModalHandler}
					acceptButtonHandler={acceptButtonHandler}
				/>
			}
		>
			<DateSection
				setStartRangeHandler={setStartRangeHandler}
				setEndRangeHandler={setEndRangeHandler}
				rangeStart={rangeStart}
				rangeEnd={rangeEnd}
				isError={isModalError.dateError}
			/>
			<Divider sx={{marginBottom: '16px'}}/>
			<CustomRadioGroup
				name='completness'
				title='Potwierdzono rzetelność i kompletność prowadzonych zapisów kontrolnych:'
				radioButtons={[
					{label: 'tak', value: true},
					{label: 'nie', value: false}
				]}
				radioValue={completeness}
				setRadioValueHandler={setCompletenessHandler}
				textShownValue={false}
				textValue={completenessText}
				onTextChange={setCompletenessTextHandler}
				isError={isModalError.completenessError}
			/>
			<Divider sx={{margin: '16px 0'}}/>
			<CustomRadioGroup
				name='incidents'
				title='Wyrób niezgodny/ zdarzenia/ incydenty:'
				radioButtons={[
					{label: 'Nie wystąpiły', value: false},
					{label: 'Wystąpiły', value: true}
				]}
				radioValue={incidents}
				setRadioValueHandler={setIncidentsHandler}
				isError={isModalError.incidentsError}
			/>
			<Divider sx={{margin: '16px 0'}}/>
			<CustomRadioGroup
				name='corrections'
				title='Korekcje i działania korygujące wynikające z przekroczeń określonych limitów kontroli zostały właściwie zapisane:'
				radioButtons={[
					{label: 'tak', value: true},
					{label: 'nie', value: false}
				]}
				radioValue={corrections}
				setRadioValueHandler={setCorrectionsHandler}
				textShownValue={false}
				textValue={correctionsText}
				onTextChange={setCorrectionsTextHandler}
				isError={isModalError.correctionsError}
			/>
			<Divider sx={{margin: '16px 0'}}/>
			<CustomRadioGroup
				name='records'
				title='Zapisy zostały pozytywnie zatwierdzone'
				radioButtons={[
					{label: 'tak', value: true},
					{label: 'nie', value: false}
				]}
				radioValue={records}
				setRadioValueHandler={setRecordsHandler}
				isError={isModalError.recordsError}
			/>
		</ModalContainer>
	)
}