import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { IIssueAnswer } from "../../../../interfaces/askExpert/askExpert";
import { ArrowDropUp } from "@mui/icons-material";
import { formatDate } from "../../../../utils";

interface IProps {
  posts: IIssueAnswer[]
}

export const Messages: FC<IProps> = ({posts}) => {
  return (
    <Grid container>
      {
        posts && posts.length > 0 && posts.map((post, index) =>
          <Grid item xs={12} mt={2} key={post.id}>
            <Accordion style={{background: post.createdBy?.toLowerCase().includes('admin') ? "#E1F5FE" : "#FFFFFF"}}>
              <AccordionSummary
                expandIcon={<ArrowDropUp/>}
                style={{color: 'text.secondary', cursor: 'pointer'}}
              >
                <Grid container mb={0} mt={1}>
                  <Grid item xs={12}>
                    <Typography>
                      {post.createdBy}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Typography fontSize='0.75rem'>Utworzono
                      • {formatDate(post.createdAt?.replaceAll('.', '-') as string, 'DD mmmm YYYY')}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{padding: '0px 16px 8px 16px'}}>
                <Typography variant='body2'>
                  {post.description}
                </Typography>
              </AccordionDetails>
              <Grid container pl={1} pr={1} pb={3}>
                {
                  post.images?.map(image =>
                    <Grid item key={image.id} xs={3} pl={1} pr={1} pt={2} mb={1} height='156px'>
                      <img src={image.src}
                           alt={post.createdBy}
                           style={{
                             width: '100%',
                             height: '100%',
                             objectFit: 'cover',
                           }}/>
                    </Grid>
                  )
                }
              </Grid>
            </Accordion>
          </Grid>
        )
      }
    </Grid>

  )
}

