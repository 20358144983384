import React, { FC } from "react";
import { Grid } from "@mui/material";
import { useImageSwiperViewModel } from "./useImageSwiper.vm";

import { Swiper, SwiperSlide } from 'swiper/react';
import './imageSwiper.scss';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from "swiper";
import { observer } from "mobx-react-lite";
import { IconButton } from "../../../../../../ui/iconButton/IconButton";
import { GenericIcon } from "../../../../../../shared/genericIcon/GenericIcon";

interface IProps {
  commentId: number;
  closeImageSwiperHandler: () => void;
}

export const ImageSwiper: FC<IProps> = observer(({commentId, closeImageSwiperHandler}): JSX.Element => {
  const {
    thumbsSwiper,
    setThumbsSwiper,
    auditImages
  } = useImageSwiperViewModel(commentId);

  return (
    <Grid className='modalContainer'>
      <Grid className='AOSImageSwiper'>
        <>
          <Swiper
            spaceBetween={10}
            navigation={true}
            loop={auditImages.length > 1}
            slideActiveClass={'swiper-slide-active'}
            thumbs={{swiper: thumbsSwiper,}}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper1"
          >
            {auditImages && auditImages.length > 0 && auditImages.map((el, index) =>
              <SwiperSlide key={el.id}><img
                src={el.src} alt={el.src}/></SwiperSlide>)}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {auditImages.length > 0 && auditImages.map((el, index) => <SwiperSlide key={el.id}><img
              src={el.src} alt={el.src}/></SwiperSlide>)}
          </Swiper>
        </>
        <IconButton
          onClick={closeImageSwiperHandler}
        >
          <GenericIcon
            iconName='Cancel'
            style={{color: 'primary.light', fontSize: '32px'}}
          />
        </IconButton>
      </Grid>
    </Grid>
  )
})