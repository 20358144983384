import React from 'react';
import { NavLink } from "react-router-dom";
import { ILink } from '../../../../interfaces';
import { Typography } from "@mui/material";
import { GenericIcon } from "../../genericIcon/GenericIcon";

interface IProps {
	linkInfo: ILink;
}

export const MenuItem: React.FC<IProps> = ({linkInfo}): JSX.Element => {
	return (
		<NavLink
			to={linkInfo.link}
			style={
				({isActive}) => ({
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					color: 'white',
					padding: '16px',
					borderBottom: `1px solid #676767`,
					background: isActive ? 'rgba(33, 150, 243, 0.08)' : 'transparent',
					textDecoration: 'none',
				})
			}
		>
			{linkInfo.ico && <GenericIcon iconName={linkInfo.ico}/>}
			<Typography variant='body2' ml={4} letterSpacing='1px'>
				{linkInfo.title}
			</Typography>
		</NavLink>
	)
}