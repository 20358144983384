import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuditOnsiteStore, useCurrentClientStore } from "../store/hooks";
import { useApiCall } from "./apiCall/useApiCall";
import { IAuditOnsiteInfo, ISingleAuditOnsiteInfo } from "../interfaces";
import { customDateFormat } from "../utils/customDateFormat";

export const useAuditOnsiteApi = () => {
  const {api} = useApiCall();
  const params = useParams();
  const {clientId, auditOnSiteId} = params;
  const currentClientStore = useCurrentClientStore();
  const auditOnsiteStore = useAuditOnsiteStore();

  const getAudits = useCallback(
    async () => {
      const res: {
        list: IAuditOnsiteInfo[],
        clientName: string
      } = await api.apiInstance.get(clientId ? `client/${clientId}/audits-completed` : 'audits-completed', true);
      if (res) {
        currentClientStore.setClientName(res.clientName, clientId);
        auditOnsiteStore.setAuditsOnsite(res.list.map(el => ({
            ...el,
            lastUpdateDate: customDateFormat(el.lastUpdateDate)
          })
        ));
      }
    }, [api.apiInstance, clientId, currentClientStore, auditOnsiteStore])

  const getAudit = useCallback(async () => {
    const res: ISingleAuditOnsiteInfo = await api.apiInstance.get(clientId ? `client/${clientId}/audits/${auditOnSiteId}` : `audits/${auditOnSiteId}`, true);

    if (res) {
      currentClientStore.setClientName(res.clientName ? res.clientName : 'client name', clientId);
      auditOnsiteStore.setAuditOnSite(res)
    }
  }, [api.apiInstance, clientId, auditOnSiteId, currentClientStore, auditOnsiteStore])

  const getAuditImages = useCallback(async (id: number) => {
    const res = await api.apiInstance.get(`audits/comment/${id}/images`, true);

    if (res) {
      auditOnsiteStore.setAuditImages(res.images)
    }
  }, [api.apiInstance, auditOnsiteStore])

  return {
    getAudits,
    getAudit,
    getAuditImages
  }
}