import { makeAutoObservable } from "mobx";
import { IAuditorInfo, IChecklistSet } from "../interfaces";

export class AuditorsStore {
    auditors: IAuditorInfo[] = [];
    availableClients: IChecklistSet[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setAuditors(auditors: IAuditorInfo[]) {
        this.auditors = auditors
    }

    addAuditor(auditor:IAuditorInfo){
        this.auditors.push(auditor)
    }

    setAvailableClients(clients: IChecklistSet[]) {
        this.availableClients = clients
    }

    get auditorsDrawArray() {
        return this.auditors
    }
}