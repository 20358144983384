import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { AppInput } from "../../../../ui/appInput/AppInput";
import { AskExpertAddModalLayout } from "../AskExpertAddModalLayout";
import { useAskExpertAddQuestionModal } from "./useAskExpertAddQuestionModal";
import { Typography } from "@mui/material";
import { useAskExpertAddModalViewModel } from "../useAskExpertAddModal.vm";

interface IProps {
  closeModalHandler: () => void;
  isModalOpen: boolean;
}

export const AskExpertAddQuestionModal: FC<IProps> = observer(({closeModalHandler, isModalOpen}) => {
  const {
    setImageFileMulti,
    imagesLink,
    imagesFile
  } = useAskExpertAddModalViewModel();
  const {formik, confirmHandler} = useAskExpertAddQuestionModal(closeModalHandler, imagesFile);
  return (
    <AskExpertAddModalLayout imagesLink={imagesLink} setImageFileMulti={setImageFileMulti}
                             closeModalHandler={closeModalHandler} isModalOpen={isModalOpen}
                             confirmHandler={confirmHandler} content={
      <>
        <Typography
          variant='h6'
          mb={2}
        >
          Zapytaj eksperta
        </Typography>
        <Typography
          variant='h6'
          mb={2}
        >
          Nowe zapytanie
        </Typography>
        <AppInput
          type='text'
          style={{
            marginBottom: '16px'
          }}
          textFieldProperties={{
            id: 'title',
            name: 'title',
            label: '*Tytuł',
            value: formik.values.title,
            onChange: formik.handleChange,
            error: formik.touched.title && Boolean(formik.errors.title),
            helperText: formik.touched.title && formik.errors.title,
          }}
        />
        <AppInput
          type='text'
          style={{
            marginBottom: '16px'
          }}
          textFieldProperties={{
            id: 'description',
            name: 'description',
            minRows: 7,
            multiline: true,
            label: '*Treść zapytania',
            value: formik.values.description,
            onChange: formik.handleChange,
            error: formik.touched.description && Boolean(formik.errors.description),
            helperText: formik.touched.description && formik.errors.description,
          }}
        />
      </>
    }/>
  )
})