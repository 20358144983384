import { useFormik } from "formik";
import { MouseEvent } from "react";
import * as yup from "yup";
import { useAskExpertApi } from "../../../../../apiHooks";
import { useParams } from "react-router-dom";
import { ILinkMulti } from "../../../../shared/uploadFiles/interfaces";

const validationSchemaQuestion = yup.object().shape({
  description: yup
    .string()
    .required('Description is required')
});

export const useAskExpertAddAnswerModal = (closeModalHandler: () => void, imagesFile: ILinkMulti[]) => {

  const {createAnswer} = useAskExpertApi(closeModalHandler)
  const params = useParams()
  const {id} = params
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: ''
    },
    validationSchema: validationSchemaQuestion,
    onSubmit: async (values) => {
      if (id) {
        const data = new FormData();

        const base64Promises = imagesFile.map((el) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64 = reader.result as string;
              resolve(base64);
            };
            reader.readAsDataURL(el.link);
          });
        });

        const base64Images = await Promise.all(base64Promises);
        base64Images.forEach((base64, index) => data.append(`images[${index}]`, base64));

        const formData = {...values, images: base64Images}

        await createAnswer(id, formData);
      }
    }
  })

  const confirmHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    formik.handleSubmit();
  }

  return {formik, confirmHandler}
}