import React, { FC } from "react";
import { Grid } from "@mui/material";
import { ActionButton } from "../actionButton/ActionButton";
import { ProjectUserRoles } from "../../../../constants";
import { useParams } from 'react-router-dom'

interface IProps {
  isViewActionEnabled?: boolean;
  viewActionHandler?: (params?: string) => void | undefined;
  viewActionAccess?: ProjectUserRoles[];
  isEditActionEnabled?: boolean;
  editActionHandler?: () => void;
  editActionAccess?: ProjectUserRoles[];
  isDeleteActionEnabled?: boolean;
  isUploadActionEnabled?: boolean;
  isArchiveActionEnabled?: boolean;
  deleteActionHandler?: (params?: string) => void | undefined;
  archiveActionHandler?: (params?: string) => void | undefined;
  deleteActionAccess?: ProjectUserRoles[];
  isCopyActionEnabled?: boolean;
  copyActionAccess?: ProjectUserRoles[];
  copyActionHandler?: (params?: string) => void;
  isDownloadActionEnabled?: boolean;
  downloadActionAccess?: ProjectUserRoles[];
  downloadActionHandler?: (params?: string) => void;
  uploadActionHandler?: (params?: string) => void;
}

export const TableActions: FC<IProps> = ({
                                           isViewActionEnabled,
                                           viewActionHandler,
                                           viewActionAccess,
                                           isEditActionEnabled,
                                           editActionHandler,
                                           editActionAccess,
                                           isDeleteActionEnabled,
                                           deleteActionHandler,
                                           deleteActionAccess,
                                           isUploadActionEnabled,
                                           isCopyActionEnabled,
                                           copyActionAccess,
                                           copyActionHandler,
                                           isDownloadActionEnabled,
                                           downloadActionAccess,
                                           downloadActionHandler,
                                           uploadActionHandler,
                                           isArchiveActionEnabled,
                                           archiveActionHandler,

                                         }): JSX.Element => {
  const params = useParams()

  return (
    <Grid
      container
      justifyContent='space-around'
      alignItems='center'
      flexWrap={'nowrap'}
      gap={2}
    >
      {
        isViewActionEnabled && (
          <ActionButton
            name='Visibility'
            onClick={viewActionHandler}
            actionAccess={viewActionAccess}
          />
        )
      }
      {
        isUploadActionEnabled && (
          <ActionButton
            name='Download'
            onClick={uploadActionHandler}
          />
        )
      }
      {
        isEditActionEnabled && (
          <ActionButton
            name='Edit'
            onClick={editActionHandler}
          />
        )
      }
      {
        isCopyActionEnabled && !params.clientId && (
          <ActionButton
            name='MoveDown'
            onClick={copyActionHandler}
            actionAccess={copyActionAccess}
          />
        )
      }
      {
        isDownloadActionEnabled && (
          <ActionButton
            name='Archive'
            onClick={downloadActionHandler}
            actionAccess={downloadActionAccess}
          />
        )
      }
      {
        isArchiveActionEnabled && (
          <ActionButton
            name='Archive'
            onClick={archiveActionHandler}

          />
        )
      }
      {
        isDeleteActionEnabled && (
          <ActionButton
            name='Delete'
            onClick={deleteActionHandler}
            actionAccess={deleteActionAccess}
          />
        )
      }
    </Grid>
  );
}