import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Page404 } from "../../../pages/page404/Page404";
import { GlobalLoader } from "../../../shared/loaders/globalLoader/GlobalLoader";
import { DashboardLayer } from "../../../shared/dashboardLayer/DashboardLayer";
import AskExpert from "../../../pages/askExpert/AskExpert";
import AskExpertDetails from "../../../pages/askExpert/askExpertDetails/AskExpertDetails";
import Reports from "../../../pages/reports/Reports";
import AuditOnsiteList from "../../../pages/audits/auditOnsiteLIst/AuditOnsiteList";
import AuditOnSite from "../../../pages/audits/auditOnSite/AuditOnSite";

const Checklists = lazy(() => import('../../../pages/checklists/Checklists'));
const Resources = lazy(() => import('../../../pages/resources/Resources'));
const Library = lazy(() => import('../../../pages/library/Library'));
const Employees = lazy(() => import('../../../pages/employees/Employees'));
const Logout = lazy(() => import('../../../pages/auth/logout/Logout'));
const Trainings = lazy(() => import('../../../pages/trainings/Trainings'));
const ChangePassword = lazy(() => import('../../../pages/auth/changePassword/ChangePassword'));
const SingleComment = lazy(() => import('../../../pages/audits/actionList/singleComment/SingleComment'));
const ActionList = lazy(() => import('../../../pages/audits/actionList/ActionList'));
const Action = lazy(() => import('../../../pages/audits/action/Action'));
const Home = lazy(() => import('../../../pages/home/Home'));
const PrivacyPolicy = lazy(() => import('../../../pages/privacyPolicy/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('../../../pages/termsOfUse/TermsOfUse'));


export const RoutesClient = () => {

  return (
    <DashboardLayer>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Home/>
            </Suspense>
          }
        />
        <Route
          path="/library"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Library/>
            </Suspense>
          }
        />
        <Route
          path="/settings"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <ChangePassword/>
            </Suspense>
          }
        />
        <Route
          path="/employees"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Employees/>
            </Suspense>
          }
        />
        <Route
          path="/trainings"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Trainings/>
            </Suspense>
          }
        />
        <Route
          path="/controls/check-lists"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Checklists/>
            </Suspense>
          }
        />
        <Route
          path="/controls/reports"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Reports/>
            </Suspense>
          }
        />
        <Route
          path="/ask-expert"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <AskExpert/>
            </Suspense>
          }
        />
        <Route
          path="/ask-expert/:id"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <AskExpertDetails/>
            </Suspense>
          }
        />
        <Route
          path="/controls/resources"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Resources/>
            </Suspense>
          }
        />
        <Route
          path="/audits-onsite"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <AuditOnsiteList/>
            </Suspense>
          }
        />
        <Route
          path='/audits-onsite/:auditOnSiteId'
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <AuditOnSite/>
            </Suspense>
          }
        />
        <Route
          path="/action-list"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <ActionList/>
            </Suspense>
          }
        />
        <Route
          path="/action-list/:actionId"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Action/>
            </Suspense>
          }
        />
        <Route
          path="action-list/:actionId/comment/:commentId"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <SingleComment/>
            </Suspense>
          }
        />
        <Route
          path="/logout"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Logout/>
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <PrivacyPolicy/>
            </Suspense>
          }
        />
        <Route
          path="/terms-of-use"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <TermsOfUse/>
            </Suspense>
          }
        />
        <Route
          path="*"
          element={<Page404/>}
        />
      </Routes>
    </DashboardLayer>
  )
}