import { useCallback } from 'react'
import { useAuthStore, useCurrentClientStore, useLibraryStore, useNotificationsStore } from "../store/hooks";
import { useApiCall } from "./apiCall/useApiCall";
import { useParams } from 'react-router-dom'
import { ProjectModalTypes, ProjectUserRoles } from "../constants";
import { NotificationType } from "../store/notificationsStore/notificationType";
import { IEditLibraryData } from "../interfaces/library/library";

import { defined } from "../utils/type-checks";
import { getPDFURLSwitcher, viewPDFURLSwitcher, getLibrarySwitcher } from '../components/pages/library/helpers';
import { fileDownload } from "../utils/fileDownload";

export const useLibraryApi = (closeModalHandler?: () => void, setPdfHandler?: (pdfFile: File | null) => void) => {
  const {api} = useApiCall();
  const params = useParams()
  const {clientId} = params
  const libraryStore = useLibraryStore();
  const {role} = useAuthStore();
  const currentClientStore = useCurrentClientStore()
  const notificationsStore = useNotificationsStore();
  const isAdmin = ProjectUserRoles.ROLE_ADMIN === role
  const isAuditor = ProjectUserRoles.ROLE_AUDITOR === role
  const isClient = ProjectUserRoles.ROLE_CLIENT === role

  const getLibraryDocuments = useCallback(async () => {
    const res = await api.apiInstance.get(getLibrarySwitcher(isAdmin, isAuditor, clientId, isClient), true)
    if (res) {
      libraryStore.setDocuments(res.list)
      libraryStore.setEmployees(res.employees)
      if (clientId && !isClient) {
        currentClientStore.setClientName(res.clientName, clientId);
      }

    }
  }, [api.apiInstance, clientId, currentClientStore, isAdmin, isAuditor, libraryStore, isClient])

  const addLibraryDocument = useCallback(async (data: any) => {
    const res = await api.apiInstance.post(clientId && isAdmin ? `client/${clientId}/document` : 'document', data, true)
    if (res) {
      libraryStore.setDocuments([...libraryStore.documents, res])
      notificationsStore.setNotification({message: 'Dokument został dodany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    }

  }, [api.apiInstance, clientId, closeModalHandler, isAdmin, libraryStore, notificationsStore])

  const editLibraryDocument = useCallback(async (data: IEditLibraryData, documentId: string) => {
    const res = await api.apiInstance.post(clientId && isAdmin ? `client/${clientId}/document/${documentId}` : `document/${documentId}`, data, true)
    if (res) {
      const currentDocuments = libraryStore.documents.map(el => el.id === documentId ? res : el);
      libraryStore.setDocuments(currentDocuments);
      notificationsStore.setNotification({message: 'Zmiany zostały zapisane.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler()
    }

  }, [api.apiInstance, clientId, closeModalHandler, isAdmin, libraryStore, notificationsStore])

  const viewLibraryFile = useCallback(async (documentId?: string, isToOpen?: boolean) => {
    const res = await api.apiInstance.get(viewPDFURLSwitcher(isAdmin, isAuditor, documentId, clientId, isClient), true, {responseType: 'blob'})
    if (res) {

      const file = new Blob([res], {type: 'application/pdf'});
      const url = URL.createObjectURL(file)
      if (isToOpen) {
        window.open(url);
        return
      }
      return url

    }
  }, [api.apiInstance, clientId, isAdmin, isAuditor, isClient])


  const deleteLibraryDocument = useCallback(async (documentId?: string) => {
    const res = await api.apiInstance.delete(clientId && isAdmin ? `client/${clientId}/document/${documentId}` : `document/${documentId}`, true)

    if (res) {
      const currentDocuments = libraryStore.documents.filter(el => el.id !== documentId);
      libraryStore.setDocuments(currentDocuments);
      notificationsStore.setNotification({message: 'Dokument został usunięty.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler();
    }

  }, [api.apiInstance, clientId, closeModalHandler, isAdmin, libraryStore, notificationsStore])

  const duplicateDocument = useCallback(async (data: any, documentId?: string) => {
    const res = await api.apiInstance.post(`document/${documentId}/duplicate`, data, true)
    if (res) {
      notificationsStore.setNotification({message: 'Dokument został skopiowany.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler();
    }

  }, [api.apiInstance, closeModalHandler, notificationsStore])

  const getPdfDocument = useCallback(async (documentIds: string, isZip: boolean = false, title: string = 'download') => {
    const res = await api.apiInstance.get(getPDFURLSwitcher(isAdmin, isAuditor, documentIds, clientId, isClient), true, {responseType: 'blob'})

    if (res) {
     fileDownload(res, isZip, title)
      notificationsStore.setNotification({message: 'Dokument został pobrany.', type: NotificationType.SUCCESS})
    }

  }, [api.apiInstance, isAdmin, isAuditor, clientId, isClient, notificationsStore])

  const submitSwitcher = useCallback(async (modalType?: string, data?: any, documentId?: string) => {
    switch (modalType) {
      case ProjectModalTypes.MODAL_TYPE_ADD:
        await addLibraryDocument(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_EDIT:
        await editLibraryDocument(data, defined(documentId));
        break;
    }
  }, [addLibraryDocument, editLibraryDocument])

  return {
    getLibraryDocuments,
    addLibraryDocument,
    deleteLibraryDocument,
    submitSwitcher,
    viewLibraryFile,
    duplicateDocument,
    getPdfDocument
  }
}