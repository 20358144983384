import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { iAudinOnsiteComment } from "../../../../../../../interfaces";
import { GenericIcon } from "../../../../../../shared/genericIcon/GenericIcon";
import { AppInput } from "../../../../../../ui/appInput/AppInput";
import { ImageSwiper } from "../imageSwiper/ImageSwiper";
import { useCommentViewModel } from "./useComment.vm";

interface IProps {
  commentInfo: iAudinOnsiteComment
}

export const Comment: FC<IProps> = ({
                                      commentInfo
                                    }): JSX.Element => {

  const {currentCommentId, closeImageSwiperHandler, openImageSwiperHandler} = useCommentViewModel()

  return (
    <Grid
      container
      direction='row'
      mt={2}
      alignItems='center'
    >
      <Grid
        item
        xs={9}
      >
        <AppInput
          type='text'
          textFieldProperties={{
            id: commentInfo.id.toString(),
            name: commentInfo.id.toString(),
            minRows: 1,
            multiline: true,
            label: '',
            value: commentInfo.text,
            disabled: true,
          }}
        />
      </Grid>
      <Grid item xs={1}/>
      {
        commentInfo.amount > 0 &&
        <Grid
          item
          xs={2}
        >
          <Grid
            container
            direction='row'
            wrap='nowrap'
            alignItems='center'
            onClick={() => openImageSwiperHandler(commentInfo.id)}
            style={{cursor: 'pointer'}}
          >
            <GenericIcon iconName='PhotoLibrary' style={{color: 'primary.light'}}/>
            <Typography variant='body1' component='p' className='labelItem--content--infoContent'>liczba
              zdjęć: {commentInfo.amount}</Typography>
          </Grid>
        </Grid>
      }
      {
        currentCommentId &&
        <ImageSwiper
          commentId={commentInfo.id}
          closeImageSwiperHandler={closeImageSwiperHandler}
        />
      }
    </Grid>
  )
}