import React from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAuditOnsiteViewModel } from "./useAuditOnsiteList.vm";
import { CustomTable } from "../../../shared/customTables/customTable/CustomTable";

const AuditOnsiteList = () => {

	const {
		tableDataRows, isDataDownloaded, headCells, currentClientId
	} = useAuditOnsiteViewModel();

	return (
		<Grid
			container
			width='100%'
			height='100%'
			maxWidth='100%'
			flexDirection='column'
		>
			<CustomTable
				data={tableDataRows}
				isDataDownloaded={isDataDownloaded}
				headCells={headCells}
				isActionsEnabled={false}
				clientId={currentClientId}
			/>
		</Grid>
	)
}

export default observer(AuditOnsiteList);