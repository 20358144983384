import React from "react";
import { observer } from "mobx-react-lite";
import { useAuditHeaderViewModel } from "./useAudytHeader.vm";
import { Grid, Typography } from "@mui/material";
import { formatDate } from "../../helpers/formatDate";

export const AuditHeader = observer((): JSX.Element => {

  const {
    clientName, auditName, createdDate, lastUpdateDate, auditorName, isClient, isAdmin
  } = useAuditHeaderViewModel();

  return (
    <Grid
      container
      flexDirection={'column'}
      p={2}
      bgcolor={"#EEE"}
      style={{borderRadius: 4, boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .12)'}}
    >
      <Typography variant='h6'>
        AUDYT {auditName && <span style={{fontWeight: 400}}>{auditName}</span>}
      </Typography>
      {!isClient && <Typography
        color={style.textColor}
        style={{textTransform: 'uppercase'}}
      >
        {clientName}
      </Typography>}
      {(isClient || isAdmin) && <Typography
        color={style.textColor}
        style={{textTransform: 'uppercase'}}
      >
        {auditorName}
      </Typography>}

      <Grid
        container
        flexDirection={'row'}
        mt={3}
      >
        <Typography color={style.textColor}> Utworzono &#8226; {formatDate(createdDate)}</Typography>
        <Typography color={style.textColor} ml={1} mr={1}>|</Typography>
        <Typography color={style.textColor}> Zmodyfikowano &#8226; {formatDate(lastUpdateDate)}</Typography>
      </Grid>

    </Grid>
  )
})

const style = {
  textColor: 'rgba(0, 0, 0, 0.6)'
}