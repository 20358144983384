const monthToPolishNameMonth: { [key: string]: string } = {
  '1': 'styczeń',
  '2': 'luty',
  '3': 'marzec',
  '4': 'kwiecień',
  '5': 'maj',
  '6': 'czerwiec',
  '7': 'lipiec',
  '8': 'sierpień',
  '9': 'wrzesień',
  '10': 'październik',
  '11': 'listopad',
  '12': 'grudzień'
};

const invalidDateStr = 'Invalid Date';
export const formatDate = (date: string): string => {

  const validDate = new Date(Date.parse(date));
  const tempDate = new Date(date);

  if (date === null || validDate.toString() === invalidDateStr) {
    return 'brak danych';
  }

  const year = tempDate.getFullYear();
  const month = tempDate.getMonth() + 1;
  const monthName = monthToPolishNameMonth[month.toString()];
  const day = tempDate.getDate();

  return `${day} ${monthName} ${year}`;
}