import { useState } from "react";
import { IModalInfo } from "./modalInterface";
import { ProjectModalTypes } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { useCurrentClientStore } from "../../../../store/hooks";

export const useModalSettings = () => {
  const currentClientStore = useCurrentClientStore();

  const navigate = useNavigate();

  const [modalInfo, setModalInfo] = useState<IModalInfo>({
    isModalOpen: false,
    modalType: ProjectModalTypes.MODAL_TYPE_UNDEFINED,
    id: undefined,
    itemName: '',
  });

  const addModalHandler = () => setModalInfo({
    isModalOpen: true,
    modalType: ProjectModalTypes.MODAL_TYPE_ADD,
    id: undefined
  });

  const editModalHandler = (id: string) => setModalInfo({
    isModalOpen: true,
    modalType: ProjectModalTypes.MODAL_TYPE_EDIT,
    id
  });

  const deleteModalHandler = (id: string, itemName: string) => setModalInfo({
    isModalOpen: true,
    modalType: ProjectModalTypes.MODAL_TYPE_DELETE,
    id,
    itemName,
  });

  const archiveModalHandler = (id: string, itemName: string) => setModalInfo({
    isModalOpen: true,
    modalType: ProjectModalTypes.MODAL_TYPE_ARCHIVE,
    id,
    itemName,
  });


  const copyModalHandler = (id: string) => setModalInfo({
    isModalOpen: true,
    modalType: ProjectModalTypes.MODAL_TYPE_COPY,
    id
  });


  // for standard view modal
  const viewModalHandler = (id: string) => {
    setModalInfo({
      isModalOpen: true,
      modalType: ProjectModalTypes.MODAL_TYPE_VIEW,
      id
    });
  }

  // for custom view modal
  const viewModalAdminHandler = (id: string, name: string) => {
    currentClientStore.setClientName(name, id);
    navigate(`/client/${id}/library`)
  }

  const closeModalHandler = () => setModalInfo({
    isModalOpen: false,
    modalType: ProjectModalTypes.MODAL_TYPE_UNDEFINED,
    id: undefined
  });

  return {
    isModalOpen: modalInfo.isModalOpen,
    modalType: modalInfo.modalType,
    id: modalInfo.id,
    itemName: modalInfo.itemName,
    addModalHandler,
    viewModalHandler,
    viewModalAdminHandler,
    copyModalHandler,
    closeModalHandler,
    editModalHandler,
    deleteModalHandler,
    archiveModalHandler
  }
}