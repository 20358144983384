import { IHeadCell, ITableData } from "../../../../interfaces";

export const createTableRows = (data: object[], headCells: IHeadCell[], isCheckBox: boolean, isUId?: boolean): ITableData[] => {
  const tableRows: ITableData[] = []
  data && data.length > 0 && data.forEach(row => {
    const tableRow: ITableData = {}

    headCells.forEach(({id, custom}) => {
      const key = id as keyof typeof row

      if (key === 'actions') return

      tableRow[key] = row[key];
    })

    if (row.hasOwnProperty('uId')) {
      tableRow['id'] = isUId ? row['id' as keyof typeof row] : row['uId' as keyof typeof row]
    } else {
      tableRow['id'] = row['id' as keyof typeof row]
    }

    tableRows.push(tableRow)
  })

  return tableRows;
}