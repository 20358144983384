import { useRootStore } from "../RootStateContext";

export const useLibraryStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.libraryStore) {
    throw new Error('libraryStore store should be defined');
  }

  return rootStore.libraryStore;
}