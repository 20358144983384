import { useRootStore } from "../RootStateContext";

export const useCurrentClientStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.currentClientStore) {
    throw new Error('currentClientStore store should be defined');
  }

  return rootStore.currentClientStore;
}