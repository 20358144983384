import React from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import { useAuditOnSiteViewModel } from "./useAuditOnSite.vm";
import { AuditHeader } from "./auditHeader/AuditHeader";
import { CategoryList } from "./category/categoryList/CategoryList";

const AuditOnSite = (): JSX.Element | null => {

  const {isDataDownloaded} = useAuditOnSiteViewModel();

  return isDataDownloaded ? (
    <Grid>
      <AuditHeader/>
      <CategoryList/>
    </Grid>
  ) : null
}

export default observer(AuditOnSite)