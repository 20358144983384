import { useRootStore } from "../RootStateContext";

export const useAuditorsStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.auditorsStore) {
        throw new Error('auditorsStore store should be defined');
    }

    return rootStore.auditorsStore;
}