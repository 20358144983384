import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUserInfo } from '../interfaces';
import { useAuthStore, useNotificationsStore } from '../store/hooks';
import { useApiCall } from './apiCall/useApiCall';
import { notificationHandler } from "./apiCall/helper";
import { NotificationType } from "../store/notificationsStore/notificationType";
import {ProjectUserRoles} from '../constants';

export const useAuthApi = () => {
  const {api} = useApiCall();
  const authStore = useAuthStore();
  const notificationsStore = useNotificationsStore();

  const navigate = useNavigate();

  const checkAuth = useCallback(async () => {
    const res: { isAuth: boolean, userInfo: IUserInfo } = await api.apiInstance.get('user/is-auth', false);
    authStore.appLogin(res);
  }, [authStore, api.apiInstance]);

  const serverLoginUser = useCallback(
    async (email: string, password: string) => {
      const res = await api.apiInstance.post('login', {
          email,
          password
        },
        true
      );

      const promise = new Promise((resolve, reject) => {
        const result =  authStore.appLogin({ userInfo: res, isAuth: true });
        resolve(result);
      });

      promise.then((result)=> {
        navigate( result  === ProjectUserRoles.ROLE_ADMIN || result  === ProjectUserRoles.ROLE_AUDITOR ? '/clients' : '/library')
      })
    },
    [api.apiInstance, authStore, navigate]
  );

  const serverLogoutUser = useCallback(async () => {
    const res = await api.apiInstance.post('logout', null, true);
    if (res) {
      authStore.appLogout();
      navigate('/')
    }
  }, [api.apiInstance, authStore, navigate]);

  const resetPassword = useCallback(
    async (email: string) => {
      const res = await api.apiInstance.put('reset-password', {email}, true);

      if (res) {
        notificationsStore.setNotification({message: res.message, type: NotificationType.SUCCESS});
        const timeout = setTimeout(() => {
          navigate('/');
          return () => clearTimeout(timeout);
        }, 5000)
      } else {
        notificationsStore.setNotification(notificationHandler(res.status, res.message));
      }
    },
    [api.apiInstance, navigate, notificationsStore]
  );

  const changePassword = useCallback(
    async (newPassword: string, token: string) => {
      const res = await api.apiInstance.put('change-password', {newPassword, token}, true);
      if (res) {
        notificationsStore.setNotification({
          message: 'Hasło zostało ustawione pomyślnie',
          type: NotificationType.SUCCESS
        });
        const timeout = setTimeout(() => {
          navigate('/');
          return () => clearTimeout(timeout);
        }, 5000)
      } else {
        notificationsStore.setNotification(notificationHandler(res.status, res.message));
      }
    },
    [api.apiInstance, navigate, notificationsStore]
  );

  const changeOwnPassword = useCallback(
    async (newPassword: string) => {
      const res = await api.apiInstance.put('change-own-password', {newPassword}, true);
      if (res) {
        notificationsStore.setNotification({
          message: 'Hasło zostało ustawione pomyślnie',
          type: NotificationType.SUCCESS
        });
        const timeout = setTimeout(() => {
          navigate('/');
          return () => clearTimeout(timeout);
        }, 5000)
      } else {
        notificationsStore.setNotification(notificationHandler(res.status, res.message));
      }
    },
    [api.apiInstance, navigate, notificationsStore]
  );

  return {
    checkAuth,
    serverLoginUser,
    serverLogoutUser,
    resetPassword,
    changePassword,
    changeOwnPassword
  };
};
