import { useEffect, useState } from "react";
import { useReportsApi } from "../../../apiHooks";
import { IHeadCell } from "../../../interfaces";
import { useReportsStore } from "../../../store/hooks";
import { createTableRows } from "../../shared/customTables/utils/createTableRows";

export const useReportsViewModel = () => {
  const reportsStore = useReportsStore();
  const reports = reportsStore.reportsDrawArray;

  const {getReports, uploadPdfReport} = useReportsApi();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([])

  const headCells: IHeadCell[] = [
    {id: 'code', numeric: false, label: 'Kod'},
    {id: 'name', numeric: false, label: 'Nazwa checklisty'},
    {id: 'resourceName', numeric: false, label: 'Zasób'},
  ]

  useEffect(() => {
    void getReports();
  }, [getReports])

  const isDataDownloaded = true;

  const tableDataRows = createTableRows(reports, headCells, true)

  const uploadActionHandler = (id: string) => {
    void uploadPdfReport(id)
  }

  const getSelectedCheckboxes = (value: string[]) => {
    setSelectedCheckboxes(value)
  }

  const generatePDFButtonHandler = () => {
    const isZip = selectedCheckboxes.length > 1
    void uploadPdfReport(selectedCheckboxes.join(','), undefined, undefined, isZip)
  }

  return {
    isDataDownloaded,
    headCells,
    tableDataRows,
    uploadActionHandler,
    selectedCheckboxes,
    getSelectedCheckboxes,
    generatePDFButtonHandler
  }
}