export const statusSwitcherHandler = (status: string): string => {
  switch (status) {
    case 'nk':
      return 'Niezgodność Krytyczna'
    case 'nd':
      return 'Niezgodność Duża'
    case 'nm':
      return 'Niezgodność Mała'
    case 'do_not_include':
      return 'No Action'
    default:
      return ''
  }
}