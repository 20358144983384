import React, { FC } from "react";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FileUploadLayout } from "../../FileUploadLayout";
import { IFileUploadProps } from "../../interfaces";
import { IImages } from "../../../../../interfaces/askExpert/askExpert";

interface IProps extends IFileUploadProps {
  imagesLink: IImages[];
  setImageFileHandler: (file: File | string | null) => void;
}

export const MultipleFileUpload: FC<IProps> = ({
                                                 imagesLink,
                                                 setImageFileHandler,
                                                 extensions,
                                                 maxFileSize
                                               }): JSX.Element => {

  return (
    <>
      <Grid item xs={12} alignSelf='stretch' height='152px'>
        <FileUploadLayout
          setFileHandler={setImageFileHandler}
          extensions={extensions}
          maxFileSize={maxFileSize}
        />
      </Grid>
      <Grid container>
        {imagesLink.map(image =>
          <Grid key={image.key} item xs={3} pl={1} pr={1} pt={2} height='152px'
                style={{position: 'relative'}}>
            <button
              onClick={() => setImageFileHandler(image.key)}
              style={{
                background: '#BDBDBD',
                border: 'none',
                borderRadius: '50%',
                padding: '4px',
                outline: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                right: '12px',
                top: '20px'
              }}
            >
              <CloseIcon sx={{width: '1em', height: '1.25rem', color: 'secondary.main'}}
                         fontSize='small'/>
            </button>
            <img
              alt="File uploading"
              src={image.src}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>
        )
        }
      </Grid>
    </>
  )
}