import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthStore, useCurrentClientStore } from "../../../../store/hooks";
import { linksByUserRole } from "./linksByUserRole";
import { ProjectUserRoles } from "../../../../constants";


export const useNavBarViewModel = () => {
  const location = useLocation();
  const authStore = useAuthStore();
  const {role} = authStore;
  const currentClientStore = useCurrentClientStore();
  const isClient = ProjectUserRoles.ROLE_CLIENT === role
  const {clientId, clientName} = currentClientStore;

  const [isNavbarOpen, setIsNavBarOpen] = useState(true)
  const [clientNameState, setClientNameState] = useState<string | undefined>(undefined)

  useEffect(() => {
    setClientNameState(clientName?.length > 0 ? clientName : undefined)
  }, [clientName])
  const toggleNavBarHandler = () => setIsNavBarOpen(prev => !prev);

  const links = linksByUserRole(role, location.pathname, clientId);

  return {
    isClient,
    isNavbarOpen,
    toggleNavBarHandler,
    links,
    clientName: clientNameState,
  }
}