import {ILink} from "../../../../../interfaces";

export const CLIENT_LINKS: ILink[] = [
  {
    title: 'Biblioteka',
    link: '/library',
    ico: 'MenuBookRounded'
  },
  {
    title: 'Audyty aktualne',
    link: '/audits-onsite',
    ico: 'FlakyRounded'
  },
  {
    title: 'Action list',
    link: `/action-list`,
    ico: 'PlaylistAddCheckCircleRounded'
  },
  {
    title: 'Pracownicy',
    link: `/employees`,
    ico: 'PeopleOutlineRounded'
  },
  {
    title: 'Kontrola i zapisy',
    link: '/controls/check-lists',
    ico: 'PlaylistAddCheckCircleRounded'
  },
  {
    title: 'Zapytaj eksperta',
    link: '/ask-expert',
    ico: 'QuestionAnswerRounded'
  },
  {
    title: 'Szkolenia',
    link: '/trainings',
    ico: 'SchoolRounded'
  },
  {
    title: 'Ustawienia',
    link: '/settings',
    ico: 'SettingsRounded'
  },
]