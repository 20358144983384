import { ILink } from "../../../../../interfaces";

export const AUDITOR_LINKS: ILink[] = [
  {
    title: 'Klienci',
    link: '/clients',
    ico: 'GroupsRounded'
  },
  {
    title: 'Ustawienia',
    link: '/settings',
    ico: 'SettingsRounded'
  }
]