import { useState } from 'react'
import { FileKey, ILinkMulti } from "../../../shared/uploadFiles/interfaces";
import { IImages } from "../../../../interfaces/askExpert/askExpert";

export const useAskExpertAddModalViewModel = () => {

  const [imagesFile, setImagesFile] = useState<ILinkMulti[]>([]);

  const [imagesLink, setImagesLink] = useState<IImages[]>([]);


  const setImageFileMulti = (file: File | FileKey | null) => {
    if (!file) {
      return;
    }

    let newImagesFile = [...imagesFile];
    let newImagesLink = [...imagesLink];

    // file === string is remove action
    if (typeof file === 'string') {

      newImagesFile = newImagesFile.filter(({key}) => {
        return key !== file
      })
      newImagesLink = newImagesLink.filter(({key}) => {
        return key !== file
      })
    } // else is add action
    else {
      const link = URL.createObjectURL(file);
      const key = file.name;
      newImagesFile.push({link: file, key});
      newImagesLink.push({src: link, key});
    }

    setImagesFile(newImagesFile);
    setImagesLink(newImagesLink);
  }


  return {
    setImageFileMulti,
    imagesLink,
    imagesFile,
  }
}
