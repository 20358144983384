import { Grid } from "@mui/material";
import React, { FC, MouseEvent } from "react";
import { observer } from "mobx-react-lite";
import { ModalContainer } from "../../../shared/modals/modalContainer/ModalContainer";
import { MultipleFileUpload } from "../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import { IImages } from "../../../../interfaces/askExpert/askExpert";

interface IProps {
  closeModalHandler: () => void;
  isModalOpen: boolean;
  confirmHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  content: JSX.Element;
  imagesLink: IImages[];
  setImageFileMulti: (file: File | string | null) => void;
}

export const AskExpertAddModalLayout: FC<IProps> = observer(({
                                                               imagesLink,
                                                               setImageFileMulti,
                                                               closeModalHandler,
                                                               isModalOpen,
                                                               confirmHandler,
                                                               content
                                                             }) => {


  return (
    <ModalContainer
      backDrop={true}
      handleClose={closeModalHandler}
      isModalOpen={isModalOpen}
      style={{
        width: '780px',
        minWidth: '650px',
      }}
      acceptButtonHandler={confirmHandler}
    >
      {content}
      <Grid
        container
        direction='row'
        mb={4}
      >
        <MultipleFileUpload maxFileSize={0.5} imagesLink={imagesLink} setImageFileHandler={setImageFileMulti}/>
      </Grid>
    </ModalContainer>
  )
})