import React from "react";
import {observer} from "mobx-react-lite";
import {useAskExpertViewModel} from "./useAskExpert.vm";
import {Grid} from "@mui/material";
import {useModalSettings} from "../../shared/modals/modalSettingsHook/useModalSettings";
import {CustomTable} from "../../shared/customTables/customTable/CustomTable";
import {ProjectModalTypes, ProjectUserRoles} from "../../../constants";
import {AskExpertDeleteModal} from "./askExpertModal/askExpertDeleteModal/AskExpertDeleteModal";
import {AskExpertAddQuestionModal} from "./askExpertModal/askExpertAddQuestionModal/AskExpertAddQuestionModal";

const AskExpert = () => {

  const {
    id: questionId,
    isModalOpen,
    modalType,
    addModalHandler,
    closeModalHandler,
    deleteModalHandler
  } = useModalSettings();
  const {
    isClient,
    tableDataRows,
    isDataDownloaded,
    headCells,
    viewActionHandler,
    itemName
  } = useAskExpertViewModel(closeModalHandler, questionId)

  return (
    <Grid
      container
      width='100%'
      maxWidth='100%'
      height='100%'
      maxHeight='100%'
      flexDirection='column'
    >
      <CustomTable
        data={tableDataRows}
        isDataDownloaded={isDataDownloaded}
        headCells={headCells}
        addModalHandler={addModalHandler}
        isActionsEnabled={true}
        isDeleteActionEnabled={true}
        deleteActionAccess={[ProjectUserRoles.ROLE_ADMIN, ProjectUserRoles.ROLE_CLIENT]}
        deleteActionHandler={deleteModalHandler}
        isAddButtonEnabled={isClient}
        viewActionHandler={viewActionHandler}
      />
      {
        isModalOpen && (
          modalType === ProjectModalTypes.MODAL_TYPE_DELETE ?
            <AskExpertDeleteModal
              itemName={itemName}
              closeModalHandler={closeModalHandler}
              isModalOpen={isModalOpen}
              questionId={String(questionId)}
            />
            :
            <AskExpertAddQuestionModal
              closeModalHandler={closeModalHandler}
              isModalOpen={isModalOpen}
            />
        )
      }
    </Grid>
  );
}

export default observer(AskExpert);