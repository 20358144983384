import { useFileUpload } from "./fileUpload/useFileUpload";
import { DropZone } from "./dropZone/DropZone";
import { GenericIcon } from "../genericIcon/GenericIcon";
import { Box, Typography } from "@mui/material";
import { FC } from "react";


interface IProps {
  setFileHandler: (file: File | null) => void;
  extensions?: string[];
  maxFileSize?: number;
  fileType?: string
}

const joinExtensionsLabel = (extensions: string[]) => {
  return extensions.reduce((text, value, i, array) => text.toUpperCase() + (i < array.length - 1 ? ', ' : ' lub ') + value.toUpperCase());
}

export const FileUploadLayout: FC<IProps> = ({
                                               setFileHandler,
                                               fileType = 'image',
                                               extensions = ['jpg', 'png', 'gif'],
                                               maxFileSize = 0.5
                                             }): JSX.Element => {
  const {
    onDragStateChange,
    onFileDrop,
    setIsDropActive,
    isDropActive,
    isFileTooBig,
    isFileNotSupported
  } = useFileUpload(setFileHandler, maxFileSize, fileType);

  return (
    <DropZone
      onDragStateChange={onDragStateChange}
      onFileDrop={onFileDrop}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        border: isDropActive ? '1px dashed #00b0ff' : '1px dashed #e0e0e0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
      }}>
      <GenericIcon iconName='UploadFile' style={{color: 'primary.light'}}/>
      <Typography>
        <label
          htmlFor="addFileButton"
          style={{
            color: '#00BDD6',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>Wybierz
          <input
            id="addFileButton"
            type="file"
            accept={extensions.join(', ')}
            style={{display: 'none'}}
            onChange={(event) => {
              event.preventDefault();
              if (event.target.files) {
                onFileDrop(event.target.files[0]);
                setIsDropActive(true);
              }
            }}
          />
        </label>&nbsp;lub przeciągnij plik
      </Typography>
      <Typography fontSize='14px'>
        <Box component={'span'} sx={{color: isFileNotSupported ? 'error.main' : 'text.secondary'}}>{joinExtensionsLabel(extensions)}</Box>
        <Box component={'span'} sx={{color: isFileTooBig ? 'error.main' : 'text.secondary'}}>{` (max. ${maxFileSize * 1000}kB)`}</Box></Typography>
    </DropZone>);
}