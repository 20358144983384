import React from "react";
import { observer } from "mobx-react-lite";
import { useLoadersStore } from "../../../store/hooks";
import { GlobalLoader } from "../../shared/loaders/globalLoader/GlobalLoader";

export const GlobalLoaderRunner = observer(() => {
	const loadersStore = useLoadersStore();
	const {isGlobalLoader} = loadersStore;

	return isGlobalLoader ? <GlobalLoader/> : null;
});