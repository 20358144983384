import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { statusSwitcherHandler } from "../../../../helpers/statusSwitcherHandler";

interface IProps {
  status: '' | 'nk' | 'nd' | 'nm' | 'do_not_include'
}

export const StatusSwitcher: FC<IProps> = ({
                                             status
                                           }): JSX.Element => {

  const statusText: string = statusSwitcherHandler(status)



  return (
    <Grid
      container
      direction='row'
      mt={2}
      alignItems='center'
    >
      <Typography variant='body1' component='p' className='labelItem--content--infoTitle'>ACTION LIST:</Typography>
      <span style={{
        padding: '4px 8px',
        backgroundColor: '#D32F2F',
        borderRadius: '16px',
        color: '#fff',
        fontSize: '12px',
        textTransform: 'uppercase',
        marginLeft: '8px'
      }}
      >
        {statusText}
      </span>
    </Grid>
  )
}