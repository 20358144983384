import {Typography} from "@mui/material";
import React, {FC} from "react";

import {observer} from "mobx-react-lite";
import {useAskExpertDeleteModalViewModel} from "./useAskExpertDeleteModal.vm";
import {ModalContainer} from "../../../../shared/modals/modalContainer/ModalContainer";
import {DeleteModal} from "../../../../shared/modals/deleteModal/DeleteModal";

interface IProps {
  closeModalHandler: () => void,
  isModalOpen: boolean;
  questionId: string;
  itemName?: string
}

export const AskExpertDeleteModal: FC<IProps> = observer(({
                                                            closeModalHandler,
                                                            isModalOpen,
                                                            questionId, itemName
                                                          }) => {

  const {
    confirmHandler,
  } = useAskExpertDeleteModalViewModel(questionId, closeModalHandler);

  return (
    <ModalContainer
      backDrop={true}
      handleClose={closeModalHandler}
      isModalOpen={isModalOpen}
      acceptButtonTitle={'Usuń'}
      style={{
        width: '780px',
        minWidth: '650px',
      }}
      acceptButtonHandler={confirmHandler}
    >
      <Typography
        variant='h6'
        mb={2}
      >
        USUWANIE ZAPYTANIA
      </Typography>
      <DeleteModal textBeforeName={'zapytanie'} itemName={itemName}/>
    </ModalContainer>
  )
})