import React, { FC } from "react";
import { Grid } from "@mui/material";
import { IAuditOnsiteFieldInfo } from "../../../../../../interfaces";
import { GroupItem } from "../groupItem/GroupItem";
import { getSingleGroupArrayHandler } from "../../../helpers/getSingleGroupArrayHandler";

interface IProps {
  categoryArray: IAuditOnsiteFieldInfo[]
}

export const GroupList: FC<IProps> = ({
                                        categoryArray
                                      }): JSX.Element => {

  const groupsTitleArray: string[] = [...new Set(categoryArray.map(el => el.group))]

  return (
    <Grid>
      {
        groupsTitleArray.map((el, index) => (
          <GroupItem key={index} groupName={el} groupArray={getSingleGroupArrayHandler(categoryArray, el)}/>
        ))
      }
    </Grid>
  )
}