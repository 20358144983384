import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApiCall } from "./apiCall/useApiCall";
import {
  useChecklistsStore,
  useCurrentClientStore,
  useNotificationsStore
} from "../store/hooks";
import { IChecklistInfo, IChecklistSet } from "../interfaces";
import { ProjectChecklistTypes, ProjectModalTypes } from "../constants";
import { NotificationType } from "../store/notificationsStore/notificationType";
import { defined } from "../utils/type-checks";

export const useChecklistsApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const params = useParams();
  const {clientId} = params;
  const checklistsStore = useChecklistsStore();
  const currentClientStore = useCurrentClientStore();
  const notificationsStore = useNotificationsStore();

  const getChecklists = useCallback(async () => {
    const res: {
      list: IChecklistInfo[];
      employees: IChecklistSet[];
      resources: { id: string, name: string }[];
      clientName?: string;
      frequencies: { [key: string]: string };
      frequenciesRare: { [key: string]: string };
      hygieneFrequencies: { [key: string]: string };
      types: { type: ProjectChecklistTypes, name: string }[]
    } = await api.apiInstance.get(clientId ? `client/${clientId}/checklists` : 'checklists', true);

    if (res) {
      checklistsStore.setChecklists(res.list);
      checklistsStore.setAvailableEmployees(res.employees);
      checklistsStore.setAvailableResources(res.resources.map(el => ({...el, uId: el.id})));
      checklistsStore.setAvailableFrequencies(res.frequencies)
      checklistsStore.setAvailableFrequenciesRare(res.frequenciesRare)
      checklistsStore.setAvailableHygieneFrequencies(res.hygieneFrequencies)
      checklistsStore.setChecklistsInfo(res.types)
      res.clientName && currentClientStore.setClientName(res.clientName, clientId);
    }

  }, [api.apiInstance, checklistsStore, currentClientStore, clientId]);

  const addChecklist = useCallback(async (checklistInfo: IChecklistInfo) => {

    const res = await api.apiInstance.post(clientId ? `client/${clientId}/checklist` : `checklist`, checklistInfo, true);
    if (res) {
      checklistsStore.addChecklist(res);
      notificationsStore.setNotification({message: 'Checklista została dodana.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, checklistsStore, notificationsStore, clientId]);

  const editChecklist = useCallback(async (checklistInfo: IChecklistInfo) => {
    const res = await api.apiInstance.put(clientId ? `client/${clientId}/checklist/${checklistInfo.id}` : `checklist/${checklistInfo.id}`, checklistInfo, true);
    if (res) {
      const currentChecklists: IChecklistInfo[] = checklistsStore.checklists.map(el => el.id === checklistInfo.id ? res : el);
      checklistsStore.setChecklists(currentChecklists)
      notificationsStore.setNotification({
        message: 'Checklista została zaktualizowana.',
        type: NotificationType.SUCCESS
      });
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, checklistsStore, notificationsStore, clientId]);

  const deleteChecklist = useCallback(async (checklistId: string) => {
    const res = await api.apiInstance.delete(clientId ? `client/${clientId}/checklist/${checklistId}` : `checklist/${checklistId}`, true);
    if (res) {
      const currentChecklists: IChecklistInfo[] = checklistsStore.checklists.filter(el => el.id !== checklistId);
      checklistsStore.setChecklists(currentChecklists)
      notificationsStore.setNotification({message: 'Checklista została usunięta.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, checklistsStore, notificationsStore, clientId]);

  const checklistSubmitSwitcher = useCallback(async (modalType: ProjectModalTypes, data: IChecklistInfo, checklistId?: string) => {
    switch (modalType) {
      case ProjectModalTypes.MODAL_TYPE_ADD:
        await addChecklist(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_EDIT:
        await editChecklist(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_VIEW:
        await editChecklist(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_DELETE:
        await deleteChecklist(defined(data.id));
        break;
      default:
        throw new Error('Wrong modal type');
    }
  }, [addChecklist, editChecklist, deleteChecklist]);

  return {
    getChecklists,
    checklistSubmitSwitcher,
  }
}