import { makeAutoObservable } from "mobx";

export class TrainingsStore {
  messageStatus: string = ''

  constructor() {
    makeAutoObservable(this);
  }

  setMessageStatus(messageStatus: string) {
    this.messageStatus = messageStatus
  }

}