import {IHeadCell, ITableData} from "../../../../interfaces";

export const countDates = (value: string) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  if (value === 'obecny miesiąc') {
    const firstDay = new Date(year, month, 1);
    const firstDayFormatted = formatDate(firstDay);
    const lastDayFormatted = formatDate(today);
    return {firstDayFormatted, lastDayFormatted};
  } else if (value === 'poprzedni miesiąc') {
    const lastDay = new Date(year, month, 0);
    const firstDay = new Date(year, month - 1, 1);
    const firstDayFormatted = formatDate(firstDay);
    const lastDayFormatted = formatDate(lastDay);
    return {firstDayFormatted, lastDayFormatted};
  } else {
    throw new Error('Nieprawidłowe słowo');
  }
}

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const createTableRows = (data: object[], headCells: IHeadCell[], isCheckBox: boolean): ITableData[] => {
  const tableRows: ITableData[] = []
  data && data.length > 0 && data.forEach(row => {
    const tableRow: ITableData = {}

    headCells.forEach(({name, custom}) => {
      const key = name as keyof typeof row
      tableRow[key] = row[key];
    })
    tableRows.push(tableRow)
  })
  return tableRows;
}