import React, { FC } from "react";
import { TableCell } from "@mui/material";
import { ReactComponent as Approved } from './../../../../../assets/icons/approved.svg'
import { ReactComponent as NotApproved } from './../../../../../assets/icons/notApproved.svg'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface IProps {
  keyName: string;
  value: string | number | boolean | JSX.Element;
}

export const CellsModifyer: FC<IProps> = ({
                                            keyName,
                                            value
                                          }): JSX.Element | null => {

  const isBoolean = typeof value == 'boolean'

  switch (true) {
    case (keyName === 'isApproved'):
      return (
        <TableCell sx={{textAlign: 'center'}}>
          {value === true ? <Approved/> : <NotApproved/>}
        </TableCell>
      )
    case isBoolean:
      return (
        <TableCell sx={{textAlign: 'center'}}>
          {value === true ? <CheckBoxIcon color={'primary'}/> :
            <CheckBoxOutlineBlankIcon/>}
        </TableCell>
      )
    default:
      return <TableCell sx={{textAlign: 'center'}}>{value}</TableCell>;
  }
}