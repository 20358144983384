import { IRecipientsInfo, NewsletterInfo } from "../interfaces/newsletter/newsletterInfo";
import { makeAutoObservable } from "mobx";

export class NewsletterStore {
  newsletterList: NewsletterInfo[] = []
  recipients: IRecipientsInfo[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setNewsletterList(newsletterList: NewsletterInfo[]) {
    this.newsletterList = newsletterList
  }

  get newsletterDrawArray() {
    return this.newsletterList
  }

  setNewsletterClientList(recipients: IRecipientsInfo[]) {
    this.recipients = recipients
  }

  get newsletterClientsDrawArray() {
    return this.recipients
  }
}

