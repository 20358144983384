import {useAskExpertStore} from "../../../../store/hooks";
import {useEffect} from "react";
import {useAskExpertApi} from "../../../../apiHooks";
import {useParams} from "react-router-dom";

export const useAskExpertDetailsViewModel = () => {
  const askExpertStore = useAskExpertStore();
  const {getQuestionDetails} = useAskExpertApi()
  const questionDetails = askExpertStore.questionDetailsDrawArray;
  const params = useParams()
  const {id} = params

  useEffect(() => {
    void getQuestionDetails(id)
  }, [getQuestionDetails, id])

  return {
    questionDetails
  }
}