import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useWindowDimensionsStore } from "../../../store/hooks";
import { useWindowDimensions } from '../../../hooks';

export const WindowDimensionsController = observer(() => {
  const windowDimensionsStore = useWindowDimensionsStore();
  const { width, height } = windowDimensionsStore;
  const { currentWindowWidth, currentWindowHeight } = useWindowDimensions();

  useEffect(() => {
    if (currentWindowWidth !== width) {
      windowDimensionsStore.setWidth(currentWindowWidth);
      windowDimensionsStore.setDisplaySize(currentWindowWidth);
    }
    if (currentWindowHeight !== height) {
      windowDimensionsStore.setHeight(currentWindowHeight);
    }
    windowDimensionsStore.setDisplayOrientation(currentWindowWidth, currentWindowHeight);
  }, [currentWindowWidth, currentWindowHeight, width, height, windowDimensionsStore]);

  return null;
});
