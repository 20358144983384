import { useRootStore } from "../RootStateContext";

export const useHomeStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.homeStore) {
    throw new Error('homeStore store should be defined');
  }

  return rootStore.homeStore;
}