import {useState} from "react";

export const useCommentViewModel = () => {

  const [currentCommentId, setCurrentCommentId] = useState<number | null>(null)

  const openImageSwiperHandler = (id: number) => setCurrentCommentId(id)
  const closeImageSwiperHandler = () => setCurrentCommentId(null)

  return {
    currentCommentId,
    openImageSwiperHandler,
    closeImageSwiperHandler,
  }
}