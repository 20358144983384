import { makeAutoObservable } from 'mobx';

export class HomeStore {
	darkMode: boolean = false;
	fontScale: number = 1;

	constructor() {
		makeAutoObservable(this);
	}

	changeMode() {
		this.darkMode = !this.darkMode;
	}

	toLightModeHandler() {
		this.darkMode = false;
	}

	increaseFontScale() {
		if (this.fontScale < 1.25) {
			this.fontScale += 0.05;
		}
	}

	decreaseFontScale() {
		if (this.fontScale > 1) {
			this.fontScale -= 0.05;
		}
	}
}