import { useRootStore } from "../RootStateContext";

export const useAuditOnsiteStore = () => {
	const rootStore = useRootStore();

	if (!rootStore?.auditOnsiteStore) {
		throw new Error('auditOnsiteStore store should be defined');
	}

	return rootStore.auditOnsiteStore;
}