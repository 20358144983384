import { ILink } from "../../../../interfaces";
import {
  ADMIN_LINKS,
  AUDITOR_LINKS,
  CLIENT_LINKS,
  additionAdminLinks,
  controlsLinks
} from "./menuLinksPatterns";
import { ProjectUserRoles } from "../../../../constants";
import { additionalAuditorLinks } from "./menuLinksPatterns/additionalAuditorLinks";

export const linksByUserRole = (role: string, path: string, clientId?: string): ILink[] => {

  switch (role) {
    case ProjectUserRoles.ROLE_ADMIN:
      switch (true) {
        case /\/client\/[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-]+\/controls\/[A-Za-z0-9]+/i.test(path):
          return [
            ...controlsLinks(clientId!),
            {
              title: 'Powrót do klienta',
              link: `/client/${clientId}/library`,
              ico: 'OtherHousesRounded'
            }];
        case path.includes('client/'):
          return additionAdminLinks(clientId!);
        default: {
          return ADMIN_LINKS;
        }
      }
    case ProjectUserRoles.ROLE_CLIENT:
      switch (true) {
        case /\/controls/i.test(path):
          return [
            ...controlsLinks(clientId!),
            {
              title: 'Powrót',
              link: `/library`,
              ico: 'ArrowBackIosRounded'
            }];
        default: {
          return CLIENT_LINKS;
        }
      }
    case ProjectUserRoles.ROLE_AUDITOR:
      switch (true) {
        case path.includes('client/'):
          return additionalAuditorLinks(clientId!);
        default: {
          return AUDITOR_LINKS;
        }
      }
    default:
      throw new Error('Nieznana rola')
  }
}