import { useAuditOnsiteStore, useAuthStore, useCurrentClientStore } from "../../../../../store/hooks";
import { ProjectUserRoles } from "../../../../../constants";

export const useAuditHeaderViewModel = () => {
  const currentClientStore = useCurrentClientStore();
  const {clientName} = currentClientStore;
  const auditOnsiteStore = useAuditOnsiteStore();
  const {auditOnSite} = auditOnsiteStore;
  const authStore = useAuthStore();
  const {role} = authStore;
  const auditName = auditOnSite?.uId ? auditOnSite?.uId : 'brak danych';
  const createdDate = auditOnSite?.createdDate ? auditOnSite?.createdDate : 'brak danych';
  const lastUpdateDate = auditOnSite?.lastUpdateDate ? auditOnSite?.lastUpdateDate : 'brak danych';
  const auditorName = auditOnSite?.auditorName ? auditOnSite?.auditorName : 'brak danych';

  const isClient = ProjectUserRoles.ROLE_CLIENT === role
  const isAdmin = ProjectUserRoles.ROLE_ADMIN === role

  return {
    clientName,
    auditName,
    createdDate,
    lastUpdateDate,
    auditorName,
    isClient,
    isAdmin
  }
}