import { makeAutoObservable } from "mobx";
import { AuthStore } from "./authStore";
import { LoadersStore } from "./loadersStore";
import { NotificationStore } from "./notificationsStore/notificationsStore";
import { WindowDimensionsStore } from "./windowDimensionsStore";
import { ClientsStore } from "./clientsStore";
import { AuditorsStore } from "./auditorsStore";
import { EmployeesStore } from "./employeesStore";
import { CurrentClientStore } from "./currentClientStore";
import { ResourcesStore } from "./resourcesStore";
import { ChecklistsStore } from "./checklistsStore";
import { AskExpertStore } from "./askExpertStore";
import { TrainingsStore } from './trainingsStore'
import { LibraryStore } from './libraryStore';
import { NewsletterStore } from './newsletterStore';
import { AuditOnsiteStore } from "./auditOnsiteStore";
import { ReportsStore } from "./reportsStore";
import { ActionListStore } from "./actionListStore";
import {HomeStore} from './homeStore';

export class RootStore {
  public loadersStore = new LoadersStore();
  public notificationsStore = new NotificationStore();
  public authStore = new AuthStore(this);
  windowDimensionsStore = new WindowDimensionsStore();
  clientsStore = new ClientsStore();
  askExpertStore = new AskExpertStore();
  auditorsStore = new AuditorsStore();
  employeesStore = new EmployeesStore();
  currentClientStore = new CurrentClientStore();
  resourcesStore = new ResourcesStore();
  checklistsStore = new ChecklistsStore();
  trainingsStore = new TrainingsStore();
  libraryStore = new LibraryStore();
  newsletterStore = new NewsletterStore();
  auditOnsiteStore = new AuditOnsiteStore();
  actionListStore = new ActionListStore();
  reportsStore = new ReportsStore();
  homeStore = new HomeStore();

  constructor() {
    makeAutoObservable(this, {
      askExpertStore: false,
      authStore: false,
      windowDimensionsStore: false,
      loadersStore: false,
      notificationsStore: false,
      clientsStore: false,
      auditorsStore: false,
      employeesStore: false,
      currentClientStore: false,
      resourcesStore: false,
      checklistsStore: false,
      trainingsStore: false,
      libraryStore: false,
      newsletterStore: false,
      auditOnsiteStore: false,
      actionListStore: false,
      reportsStore: false,
      homeStore: false,
    });
  }
}