import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';

interface IProps {
	closeModalHandler: () => void
	acceptButtonHandler?: () => void
}

export const CustomButtons: FC<IProps> = ({closeModalHandler, acceptButtonHandler}): JSX.Element => {
	return (
		<Grid
			container
			justifyContent='flex-end'
		>
			<Button
				variant='outlined'
				onClick={closeModalHandler}
				sx={{
					marginRight: '16px'
				}}
			>
				Anuluj
			</Button>
			<Button
				variant='contained'
				onClick={acceptButtonHandler}
			>
				generuj raport audytu online
			</Button>
		</Grid>
	)
}