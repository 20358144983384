import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";

interface IProps {
  points: number;
  maximumPoints: number;
}

export const CountSwitcher: FC<IProps> = ({
                                            points,
                                            maximumPoints
                                          }): JSX.Element => (
  <Grid
    container
    direction='row'
    alignItems='center'
    mt={2}
  >
    <Typography variant='body1' component='p' className='labelItem--content--infoTitle'>ilość punktów:</Typography>
    <Typography variant='body1' component='p'
                className='labelItem--content--infoContent'>{points}/{maximumPoints}</Typography>
  </Grid>
)