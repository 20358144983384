import React, { useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import logo from "../../../assets/images/logo/logo_color.svg";
import {ProjectUserRoles} from '../../../constants';
import {useAuthStore} from '../../../store/hooks';

export const Page404 = () => {
	const {role} = useAuthStore();
	const navigate = useNavigate();

	useEffect(() => {
		const timer= setTimeout(() => {
			navigate( role  === ProjectUserRoles.ROLE_ADMIN || role  === ProjectUserRoles.ROLE_AUDITOR ? '/clients' : '/library');
		}, 3000)

		return () => clearTimeout(timer)
	}, [navigate, role])

	return (
		<Grid
			container
			height='100%'
			direction='column'
			justifyContent='center'
			alignItems='center'
		>
			<img
				src={logo}
				alt="logo"
				style={{
					width: '275px',
					height: '108px',
					marginBottom: '32px'
				}}
			/>
			<Typography
				fontSize='96px'
				color='secondary.main'
				fontWeight='500'
				lineHeight='112px'
			>
				404
			</Typography>
			<Typography
				variant='body1'
				color='secondary.dark'
			>
				Niestety nie udało nam się znaleźć tej strony.
			</Typography>
			<Typography
				color='secondary.dark'
				variant='caption'
			>
				Po 3 sek. nastąpi przeniesienie na stronę główną.
			</Typography>
		</Grid>
	)
};
