import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { IAuditOnsiteFieldInfo } from "../../../../../../interfaces";
import { FlagSwitcher } from "./flagSwitcher/FlagSwitcher";
import { ValueSwitcher } from "./valueSwitcher/ValueSwitcher";
import { CountSwitcher } from "./countSwitcher/CountSwitcher";
import { StatusSwitcher } from "./statusSwitcher/StatusSwitcher";
import { Comment } from "./comment/Comment";

import '../../../scss/singleLabel.scss'

interface IProps {
  labelName: string;
  labelArray: IAuditOnsiteFieldInfo[]
}

export const LabelItem: FC<IProps> = ({
                                        labelName,
                                        labelArray,
                                      }): JSX.Element => {

  const labelInfo: IAuditOnsiteFieldInfo = labelArray[0]

  return (
    <details className='labelItem'>
      <summary className='labelItem--title'><FlagSwitcher labelValue={labelInfo.value}/> {labelName}</summary>
      <main className='labelItem--content'>
        <ValueSwitcher labelValue={labelInfo.value}/>
        {
          labelInfo.value === 'no' &&
          <StatusSwitcher status={labelInfo.status}/>
        }
        {
          labelInfo.value !== 'n/a' &&
          <CountSwitcher points={labelInfo.points} maximumPoints={labelInfo.maximumPoints}/>
        }
        {
          labelInfo.comments.length > 0 ?
            <Grid
              container
              mt={2}
              direction='column'
            >
              <Typography variant='body1' component='p' className='labelItem--content--infoTitle'>komentarz / komentarze::</Typography>
              {
                labelInfo.comments.length > 0 &&
                labelInfo.comments.map((comment) => (
                  <Comment commentInfo={comment} key={comment.id}/>
                ))
              }
            </Grid>
            : null
        }
      </main>
    </details>
  )
}