import React, { FC } from "react";
import { Chip, TableCell } from "@mui/material";
import { formatDate } from "../../../../utils";
import { NavLink } from "react-router-dom";

interface IProps {
  keyName: string;
  value: string | number;
  onClick?: () => void;
  dancePadding: string;
  articleId?: string;
  clientId?: string;
  actionId?: string;
  isActionList?: boolean;
}

export const CellsSwitcher: FC<IProps> = ({
                                            keyName,
                                            value,
                                            onClick,
                                            dancePadding,
                                            articleId,
                                            clientId,
                                            isActionList,
                                            actionId
                                          }): JSX.Element | null => {
 
  switch (keyName) {
    case 'phone':
      return (
        <TableCell
          sx={{padding: dancePadding}}
        >
          <a href={`tel:${value}`} style={aStyle}>{value}</a>
        </TableCell>
      )
    case 'email':
      return (
        <TableCell
          sx={{padding: dancePadding}}
        >
          <a href={`mailto:${value}`} style={aStyle} target="_blank" rel="noreferrer">{value}</a>
        </TableCell>
      )
    case 'id':
      return null
    case 'uId':
      return articleId ?
        (
          <TableCell>
            <NavLink
              style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}
              to={`/client/${clientId}/${isActionList ? 'action-list' : 'audits-onsite'}/${articleId}`}
            >
              {value}
            </NavLink>
          </TableCell>
        )
        : isActionList ? (
            <TableCell>
              <NavLink
                style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}
                to={`/action-list/${actionId}`}
              >
                {value}
              </NavLink>
            </TableCell>
          ) :
          (
            <TableCell>
              <NavLink
                style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}
                to={`/audits-onsite/${actionId}`}
              >
                {value}
              </NavLink>
            </TableCell>
          )
    case 'name':
      return (
        <TableCell
          style={{
            padding: dancePadding,
            cursor: 'pointer',
          }}
          onClick={() => onClick && onClick()}
        >
          {value}
        </TableCell>
      )
    case 'title' :
      return (
        <TableCell
          style={{
            padding: dancePadding,
            cursor: 'pointer',
          }}
          onClick={() => onClick && onClick()}
        >
          {value}
        </TableCell>
      )
    case 'type':
      const color = value === 'PDF' ? '#2196F3' : '#000000';

      return (
        <TableCell>
          <Chip variant="outlined" label={String(value).toUpperCase()} style={{borderColor: color, color}}/>
        </TableCell>
      )
    case 'status':
      const bcgColor = value === 'Szkic' ? '#0288D1' : '#2E7D32';
      return (
        <TableCell>
          <Chip variant="filled" label={String(value).toUpperCase()}
                style={{backgroundColor: bcgColor, color: '#FFFFFF'}}/>
        </TableCell>
      )
    case 'sentDate':
      return (
        <TableCell>
          {formatDate((value as string).slice(0, 10), 'DD.MM.YYYY')}
        </TableCell>
      )
    case 'createdAt':
    case 'updatedAt':
      return (
        <TableCell>
          {(value as string).replaceAll('-', '.')}
        </TableCell>
      )
    default:
      return <TableCell>{value}</TableCell>;
  }
}

const aStyle = {
  color: 'inherit',
  textDecoration: 'none',
}