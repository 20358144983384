import React, { CSSProperties, FC, MouseEvent, ReactNode, useEffect, useMemo } from "react";
import { Backdrop, Button, Grid, Paper, Typography } from "@mui/material";

import './ModalContainer.scss';

interface IProps {
	children: ReactNode;
	backDrop?: boolean;
	handleClose?: () => void;
	isModalOpen?: boolean;
	style?: CSSProperties;
	title?: string;
	actions?: JSX.Element;
	acceptButtonTitle?: string;
	acceptButtonHandler?: (e: MouseEvent<HTMLButtonElement>) => void;
	deleteButtonTitle?: boolean
	sendButtonHandler?: () => void;
	oneButtonTitle?: string
	customButtons?: JSX.Element
}

export const ModalContainer: FC<IProps> = ({
	                                           children,
	                                           backDrop,
	                                           handleClose,
	                                           isModalOpen,
	                                           style,
	                                           title,
	                                           actions,
	                                           acceptButtonTitle,
	                                           acceptButtonHandler,
	                                           deleteButtonTitle,
	                                           sendButtonHandler,
	                                           oneButtonTitle,
	                                           customButtons
                                           }) => {

	const bodyOverflow: HTMLBodyElement | null = useMemo(() => document.querySelector('body'), []);

	useEffect(() => {
		if (bodyOverflow) {
			bodyOverflow.style.overflow = 'hidden';
		}

		return () => {
			if (bodyOverflow) {
				bodyOverflow.style.overflow = 'auto';
			}
		};
	}, [bodyOverflow]);


	return backDrop ? (
			isModalOpen ?
				<Backdrop
					sx={{zIndex: (theme) => theme.zIndex.drawer + 1, p: 2}}
					open={isModalOpen}
				>
					<Paper
						sx={{
							p: 2,
							maxHeight: '100%',
							maxWidth: '100%',
							overflowY: 'auto',
							backgroundColor: 'background.default',
							...style,
						}}
						onClick={e => e.stopPropagation()}
					>
						{title && <Typography variant='h6' mb={2}>{title}</Typography>}
						{children}
						{
							customButtons ? customButtons : null
						}
						{
							acceptButtonHandler && (
								<Grid
									container
									justifyContent='flex-end'
									alignItems='center'
								>
									<Button
										variant='outlined'
										onClick={handleClose}
										type='button'
									>
										{oneButtonTitle || 'anuluj'}
									</Button>
									{
										!oneButtonTitle && <Button
											variant={sendButtonHandler && !deleteButtonTitle ? 'outlined' : 'contained'}
											sx={{ml: 2}}
											id={'save'}
											type='button'
											onClick={(e) => acceptButtonHandler(e)}
										>
											{acceptButtonTitle ? acceptButtonTitle : deleteButtonTitle ? 'usuń' : 'zapisz'}
										</Button>
									}
									{
										sendButtonHandler && !deleteButtonTitle && (
											<Button
												id={'send'}
												sx={{ml: 2}}
												variant='contained'
												onClick={sendButtonHandler}
												type='button'
											>
												wyślij
											</Button>
										)}
								</Grid>
							)
						}
						{
							actions
						}
					</Paper>
				</Backdrop>
				: null
		)
		:
		(
			<Grid className='modalContainer'>
				{children}
			</Grid>
		)
}