import React, { FC, useEffect } from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { pl } from "date-fns/locale";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { lastDayOfMonth } from "date-fns";

interface IProps {
	setStartRangeHandler: (date: Date | null) => void
	setEndRangeHandler: (date: Date | null) => void
	rangeStart: Date | null
	rangeEnd: Date | null
	isError?: boolean
}

const CURRENT_MONTH = 'currentMonth';
const PREVIOUS_MONTH = 'previousMonth';
const DATE_RANGE = 'dateRange';

const options = [
	{ value: CURRENT_MONTH, label: 'obecny miesiąc' },
	{ value: PREVIOUS_MONTH, label: 'poprzedni miesiąc' },
	{ value: DATE_RANGE, label: 'zakres dat' },
]

export const DateSection: FC<IProps> = ({
	rangeStart,
	rangeEnd,
	setStartRangeHandler,
	setEndRangeHandler,
	isError
}): JSX.Element => {

	const [selectedValue, setSelectedValue] = React.useState(CURRENT_MONTH);

	useEffect(() => {
		const currentDate = new Date();

		if (selectedValue === CURRENT_MONTH) {
			setStartRangeHandler(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
			setEndRangeHandler(lastDayOfMonth(currentDate))
		}

		if (selectedValue === PREVIOUS_MONTH) {
			const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
			setStartRangeHandler(new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth(), 1))
			setEndRangeHandler(lastDayOfMonth(previousMonthDate))
		}
	}, [selectedValue, setEndRangeHandler, setStartRangeHandler])


	return (
		<FormControl>
			<Typography variant='body1'>Audytowany okres:</Typography>
			<RadioGroup
				row
				aria-labelledby="period-area-label"
				name='period-area'
			>
				<Grid
					display='flex'
					flexDirection='row'
					alignItems='center'
					mb={2}
				>
					{
						options.map((option) => (
							<FormControlLabel
								key={option.value}
								value={option.value}
								control={
									<Radio
										checked={selectedValue === option.value}
										onChange={(event) => setSelectedValue(event.target.value)}
									/>
								}
								label={option.label}
							/>
						))
					}
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
						<DemoContainer components={['DatePicker', 'DatePicker']}>
							<DatePicker
								slotProps={{ textField: { size: 'small' } }}
								label="Od"
								value={rangeStart}
								disabled={!(selectedValue === 'dateRange')}
								onChange={(val: Date | null) => setStartRangeHandler(val)}
							/>
							<DatePicker
								slotProps={{ textField: { size: 'small' } }}
								label="Do"
								value={rangeEnd}
								disabled={!(selectedValue === 'dateRange')}
								onChange={(val: Date | null) => setEndRangeHandler(val)}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</Grid>
				{
					isError && <Typography color='error' variant='body2'>sprawdz zakres dat</Typography>
				}
			</RadioGroup>
		</FormControl>
	)
}