import { useCallback } from 'react'
import { useApiCall } from "./apiCall/useApiCall";
import { useAskExpertStore, useNotificationsStore } from "../store/hooks";
import { NotificationType } from "../store/notificationsStore/notificationType";
import { ICreateIssue, IIssueQuestion, IQuestionDetails } from "../interfaces/askExpert/askExpert";
import { useNavigate } from "react-router-dom";

export const useAskExpertApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const askExpertStore = useAskExpertStore();
  const notificationsStore = useNotificationsStore();
  const navigate = useNavigate();

  const getQuestions = useCallback(async () => {
    const res = await api.apiInstance.get('issues', true)

    if (res) {
      askExpertStore.setQuestionList(res.list)
    }
  }, [api.apiInstance, askExpertStore])

  const getQuestionDetails = useCallback(async (id?: string) => {
    const res: IQuestionDetails = await api.apiInstance.get(`issue/${id}`, true)

    if (res) {
      askExpertStore.setQuestionDetails(res)
    } else {
      navigate('/ask-expert')
    }
  }, [api.apiInstance, askExpertStore, navigate])

  const deleteQuestion = useCallback(async (id: string) => {
    const res = await api.apiInstance.delete(`issue/${id}`, true)

    if (res) {
      const currentQuestionList = askExpertStore.questionList.filter(el => el.id !== id)
      askExpertStore.setQuestionList(currentQuestionList)
      notificationsStore.setNotification({message: 'Zapytanie zostało usunięte.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
      navigate('/ask-expert')
    }
  }, [api.apiInstance, askExpertStore, notificationsStore, closeModalHandler, navigate])

  const createAnswer = useCallback(async (id: string, data: ICreateIssue) => {
    const res = await api.apiInstance.post(`issue/${id}/answer`, data, true)

    if (res) {
      askExpertStore.setQuestionDetails(res)
      notificationsStore.setNotification({message: 'Odpowiedź została dodana.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    }
  }, [api.apiInstance, askExpertStore, closeModalHandler, notificationsStore])

  const createQuestion = useCallback(async (data: IIssueQuestion) => {
    const res = await api.apiInstance.post('issues', data, true)
  
    if (res) {
      askExpertStore.setQuestionList([...askExpertStore.questionList, res])
      notificationsStore.setNotification({message: 'Zapytanie zostało wysłane.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    }
  }, [api.apiInstance, askExpertStore, closeModalHandler, notificationsStore])

  return {
    getQuestions,
    deleteQuestion,
    getQuestionDetails,
    createAnswer,
    createQuestion
  }
}