import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { createTheme, GlobalStyles, Grid, ThemeProvider } from "@mui/material";

import { useAuthStore, useHomeStore } from "../../store/hooks";

import { WindowDimensionsController } from "./windowDimensionsController/WindowDimensionsController";
import { AppRouting } from "./appRouting/AppRouting";
import { CheckUser } from "./checkUser/CheckUser";
import { ContentCover } from "./contentCover/ContentCover";
import { Notifications } from "./notifications/Notifications";
import { GlobalLoaderRunner } from "./GlobalLoaderRunner/GlobalLoaderRunner";
import { useLocation } from "react-router-dom";

import bgImage from '../../assets/images/mainBG.jpg';

export const AppLayout = observer(() => {
	const authStore = useAuthStore();
	const {isUserLoad} = authStore;

	const homeStore = useHomeStore();
	const {darkMode, fontScale} = homeStore;

	const location = useLocation();
	const {pathname} = location;

	const theme = useMemo(() =>
		createTheme({
			palette: {
				mode: darkMode ? 'dark' : 'light',
				background: {
					default: darkMode ? '#000' : '#fff',
				},
				primary: {
					dark: darkMode ? '#fff' : '#676767',
					main: darkMode ? '#fff' : '#004C6C',
					light: darkMode ? '#fff' : '#00BDD6',
				},
				secondary: {
					dark: darkMode ? '#000' : '#424242',
					main: '#757575',
					light: '#BDBDBD'
				},
				text: {
					primary: darkMode ? '#fff' : '#000',
				}
			},
			typography: {
				fontSize: 14 * +fontScale.toFixed(2),
				button: {
					fontWeight: 500,
					letterSpacing: '1px',
				}
			}
		}), [darkMode, fontScale]);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles
				styles={{
					body: {
						backgroundColor: darkMode ? '#000 !important' : '#fff !important',
						backgroundImage: pathname === '/' || pathname === '/terms-of-use' || pathname === '/privacy-policy' ? 'none' : `url(${bgImage})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: '100% 326px',
						backgroundPosition: 'center top',
						'@media (min-width: 600px)': {
							backgroundSize: 'auto',
						}
					}
				}}
			/>
			<Grid className='appLayer'>
				<WindowDimensionsController/>
				<CheckUser/>
				{isUserLoad && <AppRouting/>}
				<GlobalLoaderRunner/>
				<ContentCover/>
				<Notifications/>
			</Grid>
		</ThemeProvider>
	)
})