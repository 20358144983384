import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import { RootStateProvider } from "./store/RootStateContext";
import { App } from './components/App';

import './scss/main.scss';


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<BrowserRouter>
		<RootStateProvider>
			<App/>
		</RootStateProvider>
	</BrowserRouter>
);
