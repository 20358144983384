import { useRootStore } from '../RootStateContext';

export const useAuthStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.authStore) {
    throw new Error('auth store should be defined');
  }

  return rootStore.authStore;
};
