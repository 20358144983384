import { useCallback, useState } from "react";
import { useAuditOnlineApi } from "../../../../apiHooks";
import { IAuditOnlineBody } from "../../../../interfaces";

export const useAuditOnlineModalViewModel = () => {

	const { getPDF } = useAuditOnlineApi()

	const [rangeStart, setRangeStart] = useState<null | Date>(null);
	const [rangeEnd, setRangeEnd] = useState<null | Date>(null);

	const [completeness, setCompleteness] = useState<null | boolean>(null);
	const [completenessText, setCompletenessText] = useState<string>('');

	const [incidents, setIncidents] = useState<null | boolean>(null);

	const [corrections, setCorrections] = useState<null | boolean>(null);
	const [correctionsText, setCorrectionsText] = useState<string>('');

	const [records, setRecords] = useState<null | boolean>(null);

	const [isModalError, setIsModalError] = useState({
		dateError: false,
		completenessError: false,
		incidentsError: false,
		correctionsError: false,
		recordsError: false,
	});

	const setErrorHandler = (errorName: string) =>
		setIsModalError((prevState) => ({ ...prevState, [errorName]: true }))

	const clearErrorsHandler = () => setIsModalError({
		dateError: false,
		completenessError: false,
		incidentsError: false,
		correctionsError: false,
		recordsError: false,
	})

	const setCompletenessHandler = (value: boolean) => {
		setCompletenessText('')
		setCompleteness(value)
	};
	const setCompletenessTextHandler = (value: string) => setCompletenessText(value);

	const setIncidentsHandler = (value: boolean) => setIncidents(value)

	const setCorrectionsHandler = (value: boolean) => {
		setCorrectionsText('')
		setCorrections(value)
	}
	const setCorrectionsTextHandler = (value: string) => setCorrectionsText(value)


	const setRecordsHandler = (value: boolean) => setRecords(value)

	const acceptButtonHandler = () => {
		clearErrorsHandler();

		let isError = false;

		if (rangeStart === null || rangeEnd === null) {
			setErrorHandler('dateError')
			isError = true;
		}

		if (completeness === null || (!completeness && completenessText.trim() === '')) {
			setErrorHandler('completenessError')
			isError = true;
		}

		if (incidents === null) {
			setErrorHandler('incidentsError')
			isError = true;
		}

		if (corrections === null || (!corrections && correctionsText.trim() === '')) {
			setErrorHandler('correctionsError')
			isError = true;
		}

		if (records === null) {
			setErrorHandler('recordsError')
			isError = true;
		}

		if (isError) return;

		const date = new Date();
		const offset = date.getTimezoneOffset()

		const body: IAuditOnlineBody = {
			rangeStart: new Date(rangeStart!.getTime() - offset * 60000).toISOString(),
			rangeEnd: new Date(rangeEnd!.getTime() - offset * 60000).toISOString(),
			completeness,
			completenessText,
			incidents,
			corrections,
			correctionsText,
			records,
		}

		void getPDF(body)
	}

	const setStartRangeHandler = useCallback((date: Date | null) => setRangeStart(date), [])
	const setEndRangeHandler = useCallback((date: Date | null) => setRangeEnd(date), [])

	return {
		acceptButtonHandler,
		rangeStart,
		rangeEnd,
		setStartRangeHandler,
		setEndRangeHandler,
		isModalError,
		completeness,
		setCompletenessHandler,
		completenessText,
		setCompletenessTextHandler,
		incidents,
		setIncidentsHandler,
		corrections,
		setCorrectionsHandler,
		correctionsText,
		setCorrectionsTextHandler,
		records,
		setRecordsHandler,
	}
}