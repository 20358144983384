import { useRootStore } from "../RootStateContext";

export const useClientsStore = () => {
	const rootStore = useRootStore();

	if (!rootStore?.clientsStore) {
		throw new Error('clientsStore store should be defined');
	}

	return rootStore.clientsStore;
}