import React from "react";
import { MenuItem } from "./MenuItem";

import { ILink } from "../../../../interfaces";

interface IProps {
	linkList: ILink[],
	isNavbarOpen: boolean,
}

export const MenuList: React.FC<IProps> = ({linkList, isNavbarOpen}) => {

	return (
		<nav
			style={{
				marginLeft: isNavbarOpen ? '0' : '-300px',
				paddingRight: isNavbarOpen ? '0' : '60px',
				transition: 'all 0.3s ease-in-out',
			}}
		>
			{
				linkList.map((link, index) => (
					<MenuItem
						key={index}
						linkInfo={link}
					/>
				))
			}
			<MenuItem linkInfo={{
				title: 'Wyloguj',
				link: '/logout',
				ico: 'LogoutOutlined',
			}}/>
		</nav>
	)
}