import { useEffect } from "react";
import { useAuditOnsiteStore, useCurrentClientStore } from "../../../../store/hooks";
import { useAuditOnsiteApi } from "../../../../apiHooks";
import { IHeadCell } from "../../../../interfaces";
import { createTableRows } from "../../../shared/customTables/utils/createTableRows";
import { useParams } from "react-router-dom";

export const useAuditOnsiteViewModel = () => {
  const params = useParams()
  const {clientId} = params
  const auditOnsiteStore = useAuditOnsiteStore();
  const currentClientStore = useCurrentClientStore();
  const {getAudits} = useAuditOnsiteApi();

  const auditsList = auditOnsiteStore.auditsOnsiteDrawArray;

  const headCells: IHeadCell[] = [
    {id: 'uId', numeric: false, label: 'Numer audytu'},
    {id: 'lastUpdateDate', numeric: false, label: 'Data ostatniej aktualizacji'},
  ]

  const currentClientId = clientId && currentClientStore.clientId;
  const tableDataRows = createTableRows(auditsList, headCells, false, true);

  useEffect(() => {
    void getAudits();

    return () => {
      currentClientStore.clearCurrentClientStore()
    };
  }, [getAudits, currentClientStore]);

  const isDataDownloaded = true;

  return {
    tableDataRows,
    isDataDownloaded,
    headCells,
    currentClientId
  }
}