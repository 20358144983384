import { ChangeEvent, useState } from "react";
import { IHeadCell, ITableConfig, ITableData, TableSortOrder } from "../../../../interfaces";
import { defined } from "../../../../utils/type-checks";
import { getComparator } from "../utils/getComparior";
import { searchByFilter } from "../utils/searchByFilter";
import { stableSort } from "../utils/stableSort";

const initialTableConfig: ITableConfig = {
	orderBy: '',
	order: TableSortOrder.Ascending,
	page: 0,
	rowsPerPage: 10,
	searchValue: '',
	selected: [],
	selectedToState: () => {
	}
}

export const useTableController = (headCells: IHeadCell[], data: ITableData[], customTableConfig?: ITableConfig, getSelectedCheckboxes?: (value: string[]) => void) => {

	const [order, setOrder] = useState<TableSortOrder>(customTableConfig?.order || defined(initialTableConfig.order));
	const [orderBy, setOrderBy] = useState<string>(customTableConfig?.orderBy || defined(initialTableConfig.orderBy));
	const [page, setPage] = useState<number>(customTableConfig?.page || defined(initialTableConfig.page));
	const [selected, setSelected] = useState<string[]>(customTableConfig?.selected || defined(initialTableConfig.selected));
	const [rowsPerPage, setRowsPerPage] = useState<number>(customTableConfig?.rowsPerPage || defined(initialTableConfig.rowsPerPage));
	const [searchValue, setSearchValue] = useState<string>(customTableConfig?.searchValue || defined(initialTableConfig.searchValue));
	const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

	const filterChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setPage(0);
		setSearchValue(event.target.value)
	};

	const setOrderHandler = (order: TableSortOrder) => setOrder(order);

	const setOrderByHandler = (orderBy: string) => setOrderBy(orderBy);

	const changePageHandler = (event: unknown, newPage: number) => setPage(newPage);

	const setSelectedHandler = (value: string) => {
		if (selected.includes(value)) {
			setSelected(selected.filter(item => item !== value))
			getSelectedCheckboxes && getSelectedCheckboxes(selected.filter(item => item !== value))
			customTableConfig?.selectedToState?.(selected.filter(item => item !== value));
		} else {
			setSelected([...selected, value])
			getSelectedCheckboxes && getSelectedCheckboxes([...selected, value])
			customTableConfig?.selectedToState?.([...selected, value]);
		}
	}

	const allSelectToggler = (allIds: string[]) => {
		if (selected.length === allIds.length) {
			setSelected([])
			getSelectedCheckboxes && getSelectedCheckboxes([])
			customTableConfig?.selectedToState?.([]);
			setIsAllSelected(false)
		} else {
			setSelected(allIds)
			getSelectedCheckboxes && getSelectedCheckboxes(allIds)
			customTableConfig?.selectedToState?.(allIds);
			setIsAllSelected(true)
		}
	}
	const changeRowPerPageHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const createSortHandler = (property: string) => {
		const isAsc = orderBy === property && order === TableSortOrder.Ascending;
		setOrderHandler(isAsc ? TableSortOrder.Descending : TableSortOrder.Ascending);
		setOrderByHandler(property);
	}

  const isSelectedHandler = (id: string) => selected.indexOf(id) !== -1;

	const drawArray = (() => stableSort(data, getComparator(order, orderBy), orderBy === 'updatedAt' || orderBy === 'createdAt')
		.filter(searchByFilter(searchValue, headCells)))()


	return {
		tableConfig: {
			order,
			orderBy,
			page,
			rowsPerPage,
			searchValue
		},
		filterChangeHandler,
		setOrderHandler,
		setOrderByHandler,
		changePageHandler,
		changeRowPerPageHandler,
		setSelectedHandler,
		allSelectToggler,
		isAllSelected,
		isSelectedHandler,
		createSortHandler,
		drawArray
	}
}