import { makeAutoObservable } from "mobx";
import {IResourceItem, IResourcesData} from "../interfaces";

export class ResourcesStore {
    resources: IResourceItem[] = [];
    resourcesData: IResourcesData | null = null

    constructor() {
        makeAutoObservable(this);
    }

    setResources(resources: IResourceItem[]) {
        this.resources = resources
    }

    setResourcesData(resourcesData: IResourcesData) {
        this.resourcesData = resourcesData
    }

    addResource(resource: IResourceItem) {
        this.resources.push(resource);
    }

    get resourcesDrawArray() {
        return this.resources
    }

}