import {ILink} from "../../../../../interfaces";

export const ADMIN_LINKS: ILink[] = [
  {
    title: 'Klienci',
    link: '/clients',
    ico: 'GroupsRounded'
  },
  {
    title: 'Audytorzy',
    link: '/auditors',
    ico: 'AccountCircleRounded',
  },
  // {
  // 	title: 'Audyty aktualne',
  // 	link: '/audits',
  // 	ico: 'FlakyRounded'
  // },
  {
    title: 'Biblioteka',
    link: '/library',
    ico: 'MenuBookRounded'
  },
  {
    title: 'Newsletter',
    link: '/newsletter',
    ico: 'NewReleasesRounded'
  },
  {
    title: 'Zapytaj eksperta',
    link: '/ask-expert',
    ico: 'QuestionAnswerRounded'
  },
  {
    title: 'Ustawienia',
    link: '/settings',
    ico: 'SettingsRounded'
  },
]