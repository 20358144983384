import { makeAutoObservable } from "mobx";
import { IClientInfo } from "../interfaces";
import { isStringExist } from "../utils";

export class ClientsStore {
	clients: IClientInfo[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	setClients(clients: IClientInfo[]) {
		this.clients = clients.map(client => ({
			...client,
			longAddress: `${isStringExist(client.address)}${isStringExist(client.postCode)}${isStringExist(client.city, true)}`
		}))
	}

	get clientsDrawArray() {
		return this.clients
	}
}