import { useRootStore } from "../RootStateContext";

export const useTrainingsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.trainingsStore) {
    throw new Error('trainingsStore store should be defined');
  }

  return rootStore.trainingsStore;
}