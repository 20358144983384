import { useCallback } from "react";
import { useParams } from 'react-router-dom'
import { useApiCall } from "./apiCall/useApiCall";
import { useCurrentClientStore, useEmployeesStore, useNotificationsStore } from "../store/hooks";
import { IChecklistSet, IEmployeeInfo } from "../interfaces";
import { ProjectModalTypes } from "../constants";
import { NotificationType } from "../store/notificationsStore/notificationType";
import { defined } from "../utils/type-checks";

export const useEmployeesApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const params = useParams()
  const {clientId} = params
  const employeesStore = useEmployeesStore();
  const currentClientStore = useCurrentClientStore();
  const notificationsStore = useNotificationsStore();

  const getEmployees = useCallback(async () => {
      const res: { list: IEmployeeInfo[], activeChecklists: IChecklistSet[], clientName: string } = await api.apiInstance.get(clientId ? `client/${clientId}/employees` : 'employees', true);
      if (res) {
        employeesStore.setEmployees(res.list);
        employeesStore.setAvailableChecklists(res.activeChecklists);
        currentClientStore.setClientName(res.clientName, clientId);
      }
    }, [api.apiInstance, employeesStore, currentClientStore, clientId],
  );

  const addEmployee = useCallback(async (data: IEmployeeInfo) => {
    const res = await api.apiInstance.post(clientId ? `client/${clientId}/employee` : `employee`, data, true);
    if (res) {
      employeesStore.addEmployee(res);
      notificationsStore.setNotification({message: 'Użytkownik został dodany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, employeesStore, notificationsStore, clientId])

  const editEmployee = useCallback(async (data: IEmployeeInfo, employeeId: string) => {
    const res = await api.apiInstance.put(clientId ? `client/${clientId}/employee/${employeeId}` : `employee/${employeeId}`, {...data}, true);
    if (res) {
      const currentEmployees = employeesStore.employees.map(el => el.id === employeeId ? res : el);
      employeesStore.setEmployees(currentEmployees);
      notificationsStore.setNotification({message: 'Zmiany zostały zapisane.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, employeesStore, notificationsStore, clientId])

  const deleteEmployee = useCallback(async (employeeId: string) => {
    const res = await api.apiInstance.delete(clientId ? `client/${clientId}/employee/${employeeId}` : `employee/${employeeId}`, true);
    if (res) {
      const currentResources = employeesStore.employees!.filter(el => el.id !== employeeId);
      employeesStore.setEmployees(currentResources);
      notificationsStore.setNotification({message: 'Użytkownik został usunięty.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, employeesStore, notificationsStore, clientId])

  const employeesSubmitSwitcher = useCallback(async (modalType: ProjectModalTypes, data: IEmployeeInfo, employeeId?: string) => {
    switch (modalType) {
      case ProjectModalTypes.MODAL_TYPE_ADD:
        await addEmployee(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_EDIT:
        await editEmployee(data, defined(employeeId));
        break;
      case ProjectModalTypes.MODAL_TYPE_VIEW:
        await editEmployee(data, defined(employeeId));
        break;
      case ProjectModalTypes.MODAL_TYPE_DELETE:
        await deleteEmployee(defined(employeeId));
        break;
    }
  }, [addEmployee, deleteEmployee, editEmployee])

  return {
    getEmployees,
    employeesSubmitSwitcher
  }
}