import { makeAutoObservable } from "mobx";
import { IActionImages, IActionListInfo, ISingleActionInfo, ISingleComment } from "../interfaces";

export class ActionListStore {
  actionList: IActionListInfo[] = [];
  action: ISingleActionInfo | null = null;
  actionImages: IActionImages[] = [];
  singleComment: ISingleComment | null = null

  constructor() {
    makeAutoObservable(this);
  }

  setActionList(actionList: IActionListInfo[]) {
    this.actionList = actionList;
  }

  setAction(action: ISingleActionInfo) {
    this.action = action;
  }

  setActionImages(actionImages: IActionImages[]) {
    this.actionImages = actionImages;
  }

  setSingleComment(singleComment: ISingleComment) {
    this.singleComment = singleComment
  }

  clearSingleComment() {
    this.singleComment = null;
  }

  clearActionImages() {
    this.actionImages = [];
  }

  clearActionList() {
    this.actionList = [];
  }

  clearAction() {
    this.action = null;
  }

  get actionListDrawArray() {
    return this.actionList;
  }
}