import React from 'react';

import { ModalContainer } from "../../modals/modalContainer/ModalContainer";
import { Loader } from "../loader/Loader";

export const GlobalLoader = () => (
  <ModalContainer>
    <Loader/>
  </ModalContainer>
)
