import {IDocuments, IEmployees, ILibraryContent, ILibraryListElement} from "../interfaces/library/library";
import { makeAutoObservable } from "mobx";

export class LibraryStore {
  libraryFiles: ILibraryListElement[] | null = null;
  libraryFile: ILibraryContent | null = null;
  documents: IDocuments[] = []
  employees: IEmployees[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setDocuments(documents: IDocuments[]) {
    this.documents = documents
  }

  setEmployees(employees: IEmployees[]){
    this.employees = employees
  }

  get employeesDrawArray(){
    return this.employees
  }

  get libraryDocumentsDrawArray() {
    return this.documents
  }

  get libraryFilesDrawArray() {
    return this.libraryFiles
  }

  get libraryFileElement() {
    return this.libraryFile
  }

}