import { Grid, Typography } from "@mui/material";
import React from "react";

interface IProsp {
  itemName?: string;
  textAfterName?: string;
  textBeforeName?: string;
  children?: JSX.Element;
  isArchive?: boolean;
}

export const DeleteModal: React.FC<IProsp> = ({itemName, textAfterName, textBeforeName, children, isArchive}) => {
  const text = isArchive ? 'archiwizować' : 'usunąć';
  return (
    <Grid container direction={'column'}>
      <Grid container mb={2} sx={{display: 'inline'}}>
        <Typography variant='body1' sx={{display: 'inline'}}>
          Czy jesteś pewien, że chcesz {text} {textBeforeName}
        </Typography>
        <Typography variant='body1' color={'primary.main'}
                    sx={{display: 'inline'}}>{textBeforeName ? ' ' : ''}{itemName}&nbsp;</Typography>
        <Typography variant='body1' sx={{display: 'inline'}}>
          {textAfterName}?
        </Typography>
      </Grid>
      <Grid item mb={2}>{children}</Grid>
    </Grid>
  )
}