import { useAuditOnsiteStore } from "../../../../../../store/hooks";

export const useCategoryListViewModel = () => {

  const auditsOnsiteStore = useAuditOnsiteStore();
  const {auditOnSite} = auditsOnsiteStore;
  const {fields: auditOnSiteFields} = auditOnSite || {fields: []};

  return {
    auditOnSiteFields,
  }
}