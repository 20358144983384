import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";

import { IconButton } from "../iconButton/IconButton";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './AppInput.scss';

interface IProps {
	type?: string;
	style?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	textFieldProperties: {
		id: string;
		name: string;
		label: string;
		minRows?: number;
		multiline?: boolean;
		rows?: number;
		value: string;
		placeholder?: string;
		onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
		error?: boolean;
		helperText?: string | false | undefined;
		disabled?: boolean;
	}
}

export const AppInput: React.FC<IProps> = ({textFieldProperties, type, style, containerStyle}) => {

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const textFieldType = type === 'password' ? passwordIsVisible ? 'text' : 'password' : type;
  const visibilityHandler = () => setPasswordIsVisible(prev => !prev);

	return (
		<Grid
			flex={1}
			className='appInput'
			style={containerStyle}
		>
			<TextField
				fullWidth
				type={textFieldType}
				sx={style}
				size='small'
				{...textFieldProperties}
			/>
			{
				type === 'password' &&
				(
					<IconButton
						onClick={visibilityHandler}
					>
						{
							passwordIsVisible
								?
								<VisibilityOffIcon/>
								:
								<RemoveRedEyeIcon/>
						}
					</IconButton>
				)
			}
		</Grid>
	)
}