import React, { lazy, Suspense } from "react";
import { DashboardLayer } from "../../../shared/dashboardLayer/DashboardLayer";
import { Route, Routes } from "react-router-dom";
import { GlobalLoader } from "../../../shared/loaders/globalLoader/GlobalLoader";
import { Page404 } from "../../../pages/page404/Page404";

const Clients = lazy(() => import('../../../pages/clients/Clients'));
const Library = lazy(() => import('../../../pages/library/Library'));
const Logout = lazy(() => import('../../../pages/auth/logout/Logout'));
const AuditOnsiteList = lazy(() => import('../../../pages/audits/auditOnsiteLIst/AuditOnsiteList'));
const AuditOnSite = lazy(() => import('../../../pages/audits/auditOnSite/AuditOnSite'));
const ActionList = lazy(() => import('../../../pages/audits/actionList/ActionList'));
const Action = lazy(() => import('../../../pages/audits/action/Action'));
const Reports = lazy(() => import('../../../pages/reports/Reports'));
const ChangePassword = lazy(() => import('../../../pages/auth/changePassword/ChangePassword'));
const SingleComment = lazy(() => import('../../../pages/audits/actionList/singleComment/SingleComment'));
const Home = lazy(() => import('../../../pages/home/Home'));
const PrivacyPolicy = lazy(() => import('../../../pages/privacyPolicy/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('../../../pages/termsOfUse/TermsOfUse'));

export const RoutesAuditor = () => {
  return (
    <DashboardLayer>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Home/>
            </Suspense>
          }
        />
        <Route
          path="/clients"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Clients/>
            </Suspense>
          }
        />
        <Route
          path="/settings"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <ChangePassword/>
            </Suspense>
          }
        />
        <Route
          path="/client/:clientId/library"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Library/>
            </Suspense>
          }
        />
        <Route
          path="/client/:clientId/reports"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Reports/>
            </Suspense>
          }
        />
        <Route
          path="/client/:clientId/audits-onsite"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <AuditOnsiteList/>
            </Suspense>
          }
        />
        <Route
          path='/client/:clientId/audits-onsite/:auditOnSiteId'
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <AuditOnSite/>
            </Suspense>
          }
        />
        <Route
          path="/client/:clientId/action-list"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <ActionList/>
            </Suspense>
          }
        />
        <Route
          path="/client/:clientId/action-list/:actionId"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Action/>
            </Suspense>
          }
        />
        <Route
          path="/client/:clientId/action-list/:actionId/comment/:commentId"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <SingleComment/>
            </Suspense>
          }
        />
        <Route
          path="/logout"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <Logout/>
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <PrivacyPolicy/>
            </Suspense>
          }
        />
        <Route
          path="/terms-of-use"
          element={
            <Suspense fallback={<GlobalLoader/>}>
              <TermsOfUse/>
            </Suspense>
          }
        />
        <Route
          path="*"
          element={<Page404/>}
        />
      </Routes>
    </DashboardLayer>
  )
}

