import { useRootStore } from "../RootStateContext";

export const useEmployeesStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.employeesStore) {
    throw new Error('employeesStore store should be defined');
  }

  return rootStore.employeesStore;
}