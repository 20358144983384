import { makeAutoObservable } from "mobx";
import { IChecklistInfo, IChecklistSet } from "../interfaces";
import { ProjectChecklistTypes } from "../constants";

export class ChecklistsStore {
	checklists: IChecklistInfo[] = [];
	checklistsInfo: {type: ProjectChecklistTypes, name: string}[] = []
	availableEmployees: IChecklistSet[] = [];
	availableResources: IChecklistSet[] = [];
	availableFrequencies: {[key: string]: string} = {};
	availableFrequenciesRare: {[key: string]: string} = {};
	availableHygieneFrequencies: {[key: string]: string} = {};

	constructor() {
		makeAutoObservable(this);
	}

	setChecklists(checklists: IChecklistInfo[]) {
		this.checklists = checklists;
	}

	setAvailableEmployees(employees: IChecklistSet[]) {
		this.availableEmployees = employees;
	}

	setChecklistsInfo(info: {type: ProjectChecklistTypes, name: string}[]) {
		this.checklistsInfo = info;
	}

	setAvailableResources(resources: IChecklistSet[]) {
		this.availableResources = resources;
	}

	setAvailableFrequencies(frequencies: {[key: string]: string}) {
		this.availableFrequencies = frequencies;
	}

	setAvailableFrequenciesRare(frequenciesRare: {[key: string]: string}) {
		this.availableFrequenciesRare = frequenciesRare;
	}

	setAvailableHygieneFrequencies(frequencies: {[key: string]: string}) {
		this.availableHygieneFrequencies = frequencies;
	}

	addChecklist(checklist: IChecklistInfo) {
		this.checklists.push(checklist);
	}

	get checklistsDrawArray() {
		return this.checklists;
	}

}