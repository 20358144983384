import { useCallback } from "react";
import {useAuditorsStore, useNotificationsStore} from "../store/hooks";
import { useApiCall } from "./apiCall/useApiCall";
import { IAuditorInfo, IChecklistSet } from "../interfaces";
import { ProjectModalTypes } from "../constants";
import { defined } from "../utils/type-checks";
import {NotificationType} from "../store/notificationsStore/notificationType";

export const useAuditorsApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const auditorsStore = useAuditorsStore();
  const {auditors} = auditorsStore
  const notificationsStore = useNotificationsStore();

  const getAuditors = useCallback(async () => {
      const res: { list: IAuditorInfo[], clients: IChecklistSet[] } = await api.apiInstance.get('users/auditors', true);
      if (res) {
        auditorsStore.setAuditors(res.list)
        auditorsStore.setAvailableClients(res.clients)
      }
    },
    [api.apiInstance, auditorsStore]
  );

  const addAuditor = useCallback(async (data: IAuditorInfo) => {
    const res = await api.apiInstance.post('users/auditor', data, true);
    if (res) {
      auditorsStore.addAuditor(res);
      notificationsStore.setNotification({message: 'Audytor został dodany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    } else {
      auditorsStore.setAuditors(defined(auditors));
    }
  }, [api.apiInstance, auditors, auditorsStore, closeModalHandler, notificationsStore])

  const editAuditor = useCallback(async (data: IAuditorInfo, auditorId?: string) => {
    const res = await api.apiInstance.put(`users/auditor/${auditorId}`, data, true);
    if (res) {
      const currentAuditors = auditors!.map(el => el.id === auditorId ? res : el);
      auditorsStore.setAuditors(currentAuditors);
      notificationsStore.setNotification({message: 'Zmiany zostały zapisane.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }

  }, [api.apiInstance, auditors, auditorsStore, closeModalHandler, notificationsStore])

  const deleteAuditor = useCallback(async (auditorId?: string) => {
    const res = await api.apiInstance.delete(`users/auditor/${auditorId}`, true)
    if (res) {
      const currentAuditors = auditors!.filter(el => el.id !== auditorId);
      auditorsStore.setAuditors(currentAuditors);
      notificationsStore.setNotification({message: 'Audytor został usunięty.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, auditors, auditorsStore, closeModalHandler, notificationsStore])

  const switchSubmitHandler = useCallback(async (modalType: string, data: IAuditorInfo, auditorId?: string) => {
    switch (modalType) {
      case ProjectModalTypes.MODAL_TYPE_ADD:
        await addAuditor(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_EDIT:
        await editAuditor(data, auditorId);
        break;
      case  ProjectModalTypes.MODAL_TYPE_VIEW:
        await editAuditor(data, auditorId);
        break;
      case ProjectModalTypes.MODAL_TYPE_DELETE:
        await deleteAuditor(auditorId);
        break;
    }
  }, [addAuditor, editAuditor, deleteAuditor])

  return {
    getAuditors,
    switchSubmitHandler
  }
}