import {ChangeEvent, useState} from "react";
import {useReportsStore} from "../../../../../store/hooks";
import {createTableRows} from '../helpers'

export const useReportsTable = () => {
  const reportsStore = useReportsStore()
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const changePageHandler = (event: unknown, newPage: number) => setPage(newPage);

  const drawArray = reportsStore.submissionsReport?.rows

  const changeRowPerPageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const headCells = reportsStore.submissionsReport?.labels
  const tableDataRows = drawArray && headCells && createTableRows(drawArray, headCells, false)

  return {
    tableConfig: {
      page,
      rowsPerPage,
    },
    tableDataRows,
    changePageHandler,
    changeRowPerPageHandler,
    headCells
  }
}