import React from "react";
import { observer } from "mobx-react-lite";
import { useAskExpertDetailsViewModel } from "./useAskExpertDetails.vm";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { formatDate } from "../../../../utils";
import { Messages } from "./Messages";
import CloseIcon from "@mui/icons-material/Close";
import { GenericIcon } from "../../../shared/genericIcon/GenericIcon";
import { useModalSettings } from "../../../shared/modals/modalSettingsHook/useModalSettings";
import { ProjectModalTypes } from "../../../../constants";
import { AskExpertDeleteModal } from "../askExpertModal/askExpertDeleteModal/AskExpertDeleteModal";
import { AskExpertAddAnswerModal } from "../askExpertModal/askExpertAddAnswerModal/AskExpertAddAnswerModal";

const AskExpertDetails = () => {
  const {
    id: questionId,
    isModalOpen,
    modalType,
    addModalHandler,
    closeModalHandler,
    deleteModalHandler
  } = useModalSettings();
  const {questionDetails} = useAskExpertDetailsViewModel()

  return (
    <Grid
      container
      flexDirection='row'
    >
      {questionDetails && <> <Grid item xs={12}>
        <Card style={{background: '#EEEEEE'}}>
          <CardContent style={{padding: '1rem', position: 'relative'}}>
            <button
              onClick={() => deleteModalHandler(questionDetails.id, questionDetails.title)}
              style={{
                border: 'none',
                outline: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                top: 'calc(50% - 1rem)',
                position: 'absolute',
                right: '1rem'
              }}
            >
              {
                <CloseIcon style={{color: 'secondary.main'}}/>
              }
            </button>
            <Typography fontSize='0.875rem'
                        color='secondary.main'
                        letterSpacing='0.1px'>{questionDetails.number}</Typography>
            <Typography variant='h6'>{questionDetails.title}</Typography>
            <Typography fontSize='0.875rem'>{questionDetails.client}</Typography>
            <Typography fontSize='0.75rem'>Utworzono
              • {formatDate(questionDetails.createdAt.replace('.', '-'), 'DD mmmm YYYY')}</Typography>
          </CardContent>
        </Card>

        <Grid container mt={2} pl={1} pr={1} pb={3} style={{background: 'white'}}>
          <Grid item xs={12} mt={2}>
            <Typography fontSize='0.75rem'>Utworzono
              • {formatDate(questionDetails.createdAt.replace('.', '-')!, 'DD mmmm YYYY')}</Typography>
            <Typography variant='body2'>
              {questionDetails.description}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} display={'flex'}>
            {
              questionDetails.images && questionDetails.images.length > 0 && questionDetails.images.map(image =>
                <Grid item key={image.id} xs={3} pl={1} pr={1} pt={2} mb={1} height='156px'>
                  <img src={image.src}
                       alt={image.src}
                       style={{
                         width: '100%',
                         height: '100%',
                         objectFit: 'cover',
                       }}/>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Grid>
        <Grid item xs={12}>
          <Messages
            posts={questionDetails.issuesAnswers}
          />
        </Grid>
      </>}
      <Grid item xs={12} mt={4} mb={3} display="flex" justifyContent="center">
        <Button
          variant='contained'
          onClick={addModalHandler}
          sx={{borderRadius: '64px'}}
        >
          <GenericIcon iconName='Add'/>
          <Typography variant='body1' sx={{ml: '12px', fontWeight: '500'}}>
            DODAJ
          </Typography>
        </Button>
      </Grid>
      {
        isModalOpen && (
          modalType === ProjectModalTypes.MODAL_TYPE_DELETE ?
            <AskExpertDeleteModal
              itemName={questionDetails?.title}
              closeModalHandler={closeModalHandler}
              isModalOpen={isModalOpen}
              questionId={String(questionId)}
            />
            :
            <AskExpertAddAnswerModal
              closeModalHandler={closeModalHandler}
              isModalOpen={isModalOpen}
            />
        )
      }
    </Grid>
  );
}

export default observer(AskExpertDetails);