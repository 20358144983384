import { Button, Grid, Typography } from "@mui/material";
import { AppInput } from "../../ui/appInput/AppInput";
import { GenericIcon } from "../genericIcon/GenericIcon";
import { ProjectUserRoles } from "../../../constants";

interface IProps {
	addModalHandler?: () => void;
	addActionAccess?: ProjectUserRoles[];
	filterValue: string;
	filterChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isWhiteArea?: boolean;
	isAddButtonEnabled?: boolean;
	additionalResourceElement?: JSX.Element;
	isGeneratePDFButtonEnabled?: boolean;
	isButtonDisabled?: boolean;
	isGeneratePDFButtonDisabled?: boolean;
	generatePDFButtonHandler?: () => void;
	isAuditOnlineEnabled?: boolean;
	openAuditOnlineModalHandler?: () => void;
}

export const SearchBar: React.FC<IProps> = ({
	                                            addModalHandler,
	                                            addActionAccess,
	                                            filterValue,
	                                            filterChangeHandler,
	                                            isWhiteArea,
	                                            isAddButtonEnabled,
	                                            additionalResourceElement,
	                                            isGeneratePDFButtonEnabled,
	                                            generatePDFButtonHandler,
	                                            isButtonDisabled,
	                                            isGeneratePDFButtonDisabled,
	                                            isAuditOnlineEnabled,
	                                            openAuditOnlineModalHandler
                                            }) => {

	return (
		< Grid
			container
			bgcolor={isWhiteArea ? 'white' : 'secondary.light'}
			p={isWhiteArea ? '0px' : 2}
			mb={isWhiteArea ? 2 : 0}
			justifyContent='space-between'
			alignItems='center'
			flexWrap='nowrap'
			sx={{
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',
			}}
		>
			<AppInput
				type='text'
				style={{
					minWidth: '300px',
					width: isWhiteArea ? '100%' : '300px',
				}}
				textFieldProperties={{
					id: 'search',
					name: 'search',
					label: 'Wyszukaj',
					placeholder: 'Imię, email, etc...',
					value: filterValue,
					onChange: filterChangeHandler,
				}}
			/>
			{/*BUTTON AREA*/}
			{additionalResourceElement}
			{
				isAddButtonEnabled && (
					<Grid
						item
					>
						<Grid
							container
							gap={2}
						>
							<Button
								variant='contained'
								onClick={addModalHandler}
								disabled={isButtonDisabled}
							>
								<GenericIcon iconName='Add'/>
								<Typography variant='body1' sx={{ml: '12px', fontWeight: '500'}}>
									DODAJ
								</Typography>
							</Button>
						</Grid>
					</Grid>)}

			{
				isGeneratePDFButtonEnabled && (
					<Grid
						item
					>
						<Grid
							container
							gap={2}
							pl={2}
						>
							{
								isAuditOnlineEnabled && (
									<Button
										variant='contained'
										onClick={openAuditOnlineModalHandler}
									>
										<GenericIcon iconName='PictureAsPdf'/>
										<Typography variant='body1'>
											RAPORT AUDYTU ONLINE
										</Typography>
									</Button>
								)
							}
							<Button
								variant='contained'
								onClick={generatePDFButtonHandler}
								disabled={isGeneratePDFButtonDisabled}
							>
								<GenericIcon iconName='PictureAsPdf'/>
								<Typography variant='body1' sx={{ml: '12px', fontWeight: '500'}}>
									{
										isAuditOnlineEnabled ? 'GENERUJ RAPORTY PDF' : 'GENERUJ PDF'
									}
								</Typography>
							</Button>
						</Grid>
					</Grid>)}
		</Grid>
	)
}