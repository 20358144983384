import { useRootStore } from "../RootStateContext";

export const useChecklistsStore = () => {
	const rootStore = useRootStore();

	if (!rootStore?.checklistsStore) {
		throw new Error('checklists store should be defined');
	}

	return rootStore.checklistsStore;
}