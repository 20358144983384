export const getPDFURLSwitcher = (isAdmin: boolean, isAuditor: boolean, documentIds: string, clientId?: string, isClient?: boolean) => {
  switch (true) {
    case (clientId && isAdmin):
      return `client/${clientId}/documents/pdfs?ids=${documentIds}`;
    case (isAuditor):
      return `auditor/client/${clientId}/documents/pdfs?ids=${documentIds}`;
    case (isAdmin || isClient):
      return `documents/pdfs?ids=${documentIds}`;
    default:
      return `employee/client/${clientId}/documents/pdfs?id=${documentIds}`
  }
}

export const viewPDFURLSwitcher = (isAdmin: boolean, isAuditor: boolean, documentId?: string, clientId?: string, isClient?: boolean) => {
  switch (true) {
    case (clientId && isAdmin):
      return `client/${clientId}/document/${documentId}`;
    case (isAuditor):
      return `auditor/client/${clientId}/document/${documentId}`
    case (isAdmin || isClient):
      return `document/${documentId}`;
    default:
      return `employee/client/${clientId}/document/${documentId}`
  }
}


export const getLibrarySwitcher = (isAdmin: boolean, isAuditor: boolean, clientId?: string, isClient?: boolean) => {
  switch (true) {
    case (clientId && isAdmin):
      return `client/${clientId}/documents`
    case (isAuditor):
      return `auditor/client/${clientId}/documents`
    case (isAdmin || isClient):
      return `documents`;
    default:
      return `employee/client/${clientId}/documents`
  }
}