import { useCallback } from "react";
import { useResourcesStore, useCurrentClientStore, useNotificationsStore } from "../store/hooks";
import { useApiCall } from "./apiCall/useApiCall";
import { IResourceData } from "../interfaces";
import { useParams } from 'react-router-dom'
import { defined } from "../utils/type-checks";
import { ProjectModalTypes } from "../constants";
import { NotificationType } from "../store/notificationsStore/notificationType";

export const useResourcesApi = (closeModalHandler?: () => void) => {
  const {api} = useApiCall();
  const resourcesStore = useResourcesStore();
  const {resources, resourcesData} = resourcesStore
  const currentClientStore = useCurrentClientStore();
  const notificationsStore = useNotificationsStore();
  const params = useParams()
  const {clientId} = params

  const getResources = useCallback(async () => {
      const res = await api.apiInstance.get(clientId ? `client/${clientId}/resources` : 'resources', true);
      if (res) {
        resourcesStore.setResources(res.list)
        resourcesStore.setResourcesData({resourcesAmount: res.resourcesAmount, resourcesLimit: res.resourcesLimit})
        currentClientStore.setClientName(res.clientName, clientId);
      }
    },
    [api.apiInstance, resourcesStore, currentClientStore, clientId]
  );

  const addResource = useCallback(async (data: IResourceData) => {
    const res = await api.apiInstance.post(clientId ? `client/${clientId}/resource` : 'resource', data, true);
    if (res) {
      resourcesStore.addResource(res);
      resourcesStore.setResourcesData({
        ...resourcesData,
        resourcesAmount: (Number(resourcesData?.resourcesAmount) + 1).toString()
      })
      notificationsStore.setNotification({message: 'Zasób został dodany.', type: NotificationType.SUCCESS});
      closeModalHandler && closeModalHandler()
    } else {
      resourcesStore.setResources(defined(resources));
    }
  }, [api.apiInstance, closeModalHandler, resources, resourcesData, resourcesStore, notificationsStore, clientId])

  const editResource = useCallback(async (data: IResourceData, resourceId?: string) => {
    const res = await api.apiInstance.put(clientId ? `client/${clientId}/resource/${resourceId}` : `resource/${resourceId}`, data, true);
    if (res) {
      const currentResources = resources!.map(el => el.id === resourceId ? res : el);
      resourcesStore.setResources(currentResources);
      notificationsStore.setNotification({message: 'Zmiany zostały zapisane.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, resources, resourcesStore, notificationsStore, clientId])

  const deleteResource = useCallback(async (resourceId?: string) => {
    const res = await api.apiInstance.delete(clientId ? `client/${clientId}/resource/${resourceId}` : `resource/${resourceId}`, true);
    if (res) {
      const currentResources = resources!.filter(el => el.id !== resourceId);
      resourcesStore.setResources(currentResources);
      resourcesStore.setResourcesData({
        ...resourcesData,
        resourcesAmount: (Number(resourcesData?.resourcesAmount) - 1).toString()
      })
      notificationsStore.setNotification({message: 'Zasób został usunięty.', type: NotificationType.SUCCESS})
      closeModalHandler && closeModalHandler();
    }
  }, [api.apiInstance, closeModalHandler, resources, resourcesStore, resourcesData, notificationsStore, clientId])

  const switchSubmitHandler = useCallback(async (modalType: string, data: IResourceData, resourceId?: string) => {
    switch (modalType) {
      case ProjectModalTypes.MODAL_TYPE_ADD:
        await addResource(data);
        break;
      case ProjectModalTypes.MODAL_TYPE_EDIT:
        await editResource(data, resourceId);
        break;
      case ProjectModalTypes.MODAL_TYPE_VIEW:
        await editResource(data, resourceId);
        break;
      case ProjectModalTypes.MODAL_TYPE_DELETE:
        await deleteResource(resourceId);
        break;
      case ProjectModalTypes.MODAL_TYPE_ARCHIVE:
        await deleteResource(resourceId);
        break;
    }
  }, [addResource, deleteResource, editResource])

  return {
    getResources,
    switchSubmitHandler
  }
}