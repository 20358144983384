import React, { FC } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { AppInput } from "../../../ui/appInput/AppInput";

interface IProps {
	name: string
	title: string
	radioButtons: { label: string, value: boolean }[]
	radioValue: null | boolean
	setRadioValueHandler: (value: boolean) => void
	textShownValue?: string | boolean
	textValue?: string
	onTextChange?: (value: string) => void
	isError?: boolean
}

export const CustomRadioGroup: FC<IProps> = ({
	                                             name,
	                                             title,
	                                             radioButtons,
	                                             radioValue,
	                                             setRadioValueHandler,
	                                             textShownValue,
	                                             textValue,
	                                             onTextChange,
																							 isError
                                             }): JSX.Element => {


	return (
		<FormControl fullWidth>
			<Typography variant='body1'>{title}</Typography>
			<RadioGroup
				row
				name={name}
				aria-labelledby={`${name}-area-label`}
			>
				<Radio
					checked={radioValue === null}
					sx={{display: 'none'}}
				/>
				{
					radioButtons.map((radioButton, index) => (
						<FormControlLabel
							key={`${name}-${index}`}
							value={radioButton.value}
							control={
								<Radio
									checked={radioValue === radioButton.value}
									onChange={() => setRadioValueHandler(radioButton.value)}
									/>
							}
							label={radioButton.label}
						/>
					))
				}
			</RadioGroup>
			{
				textShownValue === radioValue && (
					<AppInput
						type='text'
						style={{
							width: '100%',
						}}
						textFieldProperties={{
							id: `${name}-text`,
							name: `${name}-text`,
							minRows: 4,
							multiline: true,
							label: 'Szczegóły',
							value: textValue || '',
							onChange: (e) => onTextChange && onTextChange(e.target.value),
						}}
					/>
				)
			}
			{
				isError && <Typography variant='body2' color='red'>To pole jest wymagane</Typography>
			}
		</FormControl>
	)
}