import { useFormik } from "formik";
import { MouseEvent } from "react";
import * as yup from "yup";
import { ILinkMulti } from "../../../../shared/uploadFiles/interfaces";
import { useAskExpertApi } from "../../../../../apiHooks";

const validationSchemaQuestion = yup.object().shape({
  title: yup
    .string()
    .required('Title is required'),
  description: yup
    .string()
    .required('Content is required')
});


export const useAskExpertAddQuestionModal = (closeModalHandler: () => void, imagesFile: ILinkMulti[]) => {
  const {createQuestion} = useAskExpertApi(closeModalHandler)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      description: ''
    },
    validationSchema: validationSchemaQuestion,
    onSubmit: async (values) => {
      const data = new FormData();
      data.append('title', values.title);
      data.append('description', values.description);
      const base64Promises = imagesFile.map((el) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = reader.result as string;
            resolve(base64);
          };
          reader.readAsDataURL(el.link);
        });
      });

      const base64Images = await Promise.all(base64Promises);
      base64Images.forEach((base64, index) => data.append(`images[${index}]`, base64));
      const formData = {...values, images: base64Images}

      await createQuestion(formData)

    }
  })

  const confirmHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    formik.handleSubmit();
  }

  return {formik, confirmHandler}
}