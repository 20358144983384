import { Grid } from "@mui/material";
import React from "react";
import { NavBar } from "./navBar/navBar";
import {useLocation} from 'react-router-dom';

interface IProps {
  children: React.ReactNode
}

export const DashboardLayer: React.FC<IProps> = ({children}) => {
  const location = useLocation();

  const isHomePage = location.pathname === '/'

  return (
    <Grid
      container
      direction='row'
      flexWrap='nowrap'
    >
      {!isHomePage && <NavBar/>}
      <Grid
        item
        style={{
          padding: isHomePage ? '0' : '16px',
          flexGrow: 1,
          height: '100vh',
          width: 'calc(100vw - 240px)',
          maxHeight: '100vh',
          overflowY: 'auto',
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}