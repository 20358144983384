type DateFormatOption =
  'locale'
  | 'YYYY.MM.DD'
  | 'DD.MM.YYYY'
  | 'MM/DD/YYYY'
  | 'Mmm DD, YYYY'
  | 'DD mmm YYYY'
  | 'DD mmmm YYYY'
  | 'YYYY-MM-DD';

const monthToShortMonth: { [key: string]: string } = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

const monthToPolishNameMonth: { [key: string]: string } = {
  '1': 'styczeń',
  '2': 'luty',
  '3': 'marzec',
  '4': 'kwiecień',
  '5': 'maj',
  '6': 'czerwiec',
  '7': 'lipiec',
  '8': 'sierpień',
  '9': 'wrzesień',
  '10': 'październik',
  '11': 'listopad',
  '12': 'grudzień'
};

const invalidDateStr = 'Invalid Date';

/*
    @param date - date in string type. if you use a Date object, convert it to string
    @param dateFormat - you choose a format type compatible with DateFormatOption or expand it by new format

    @example:
    formatDate('2022-03-23, 'locale');
*/

// export function formatDate(date: string, dateFormat: DateFormatOption): string | null {
export function formatDate(date: string, dateFormat: DateFormatOption): string {
  if (date === null) {
    return date;
  }

  // check if the date is correct. If so then split it.
  const validDate = new Date(Date.parse(date));

  if (validDate.toString() === invalidDateStr) {
    return date;
  }

  const splitDate = date.split(/-|\+|Z/);

  const year = splitDate[0];
  const month = splitDate[1];
  const day = splitDate[2];
  const truncatedMonth = month?.replace(/^0+/, '');

  // find the date format and parse it.
  switch (dateFormat) {
    case 'locale':
      return validDate.toLocaleDateString();
    case 'YYYY.MM.DD':
      return `${year}.${month}.${day}`;
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`;
    case 'DD.MM.YYYY':
      return `${day}.${month}.${year}`;
    case 'MM/DD/YYYY':
      return `${month}/${day}/${year}`;
    case 'DD mmm YYYY':
      return `${day} ${monthToPolishNameMonth[truncatedMonth]} ${year}`;
    case 'DD mmmm YYYY':
      return `${parseInt(year)}  ${monthToPolishNameMonth[truncatedMonth]} ${day} `;
    case 'Mmm DD, YYYY':
      return `${monthToShortMonth[truncatedMonth]} ${day}, ${year}`;
    default:
      return date;
  }
}

