import React, { FC } from 'react';
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography
} from '@mui/material';
import { visuallyHidden } from "@mui/utils";
import { IHeadCell, ITableConfig, ITableData, TableSortOrder } from "../../../../interfaces";
import { SearchBar } from "../../searchBar/SearchBar";
import { useTableController } from "../../../../hooks";
import { TableActions } from "../tableActions/TableActions";
import { ProjectUserRoles } from "../../../../constants";
import { CellsSwitcher } from "../cells/CellsSwitcher";
import { CheckBoxCell } from "../cells/CheckBoxCell";
import { observer } from 'mobx-react-lite'

interface IProps {
	// fetched list we pass to component
	data: ITableData[];
	// boolean value checking if data was fetched
	isDataDownloaded: boolean;
	// list of names of column header
	headCells: IHeadCell[];
	// object specifying settings for table like order, rows per page etc.
	customTableConfig?: ITableConfig;
	// list of roles that may access to view action
	addActionAccess?: ProjectUserRoles[];
	// function that triggers the opening of the modal when clicked "Dodaj"
	addModalHandler?: () => void;
	// boolean value checking if actions like edit/delete are enabled
	isActionsEnabled: boolean;
	// boolean value checking if action like 'view' is enabled
	isViewActionEnabled?: boolean;
	// list of roles that may access to view action
	viewActionAccess?: ProjectUserRoles[];
	// function that triggers the opening of the modal when view clicked
	viewActionHandler?: (id: string, name: string) => void;
	// boolean value checking if edit action is enabled
	isEditActionEnabled?: boolean;
	// list of roles that may access to edit action
	editActionAccess?: ProjectUserRoles[];
	// function that triggers the opening of the modal when edit clicked
	editActionHandler?: (params: string) => void;
	// boolean value checking if archive action is enabled
	isArchiveActionEnabled?: boolean;
	// function that triggers the opening of the modal when archive clicked
	archiveActionHandler?: (id: string, name: string) => void;
	// boolean value checking if delete action is enabled
	isDeleteActionEnabled?: boolean;
	// list of roles that may access to delete action
	deleteActionAccess?: ProjectUserRoles[];
	// function that triggers the opening of the modal when delete clicked
	deleteActionHandler?: (id: string, name: string) => void;
	// boolean value checking if copy action is enabled
	isCopyActionEnabled?: boolean;
	// list of roles that may access to copy action
	copyActionAccess?: ProjectUserRoles[];
	// function that triggers the opening of the modal when copy clicked
	copyActionHandler?: (params: string) => void;
	// boolean value checking if download action is enabled
	isDownloadActionEnabled?: boolean;
	// list of roles that may access to download action
	downloadActionAccess?: ProjectUserRoles[];
	// function that triggers the opening of the modal when download clicked
	downloadActionHandler?: (params: string, title?: string) => void
	// boolean value checking if upload action is enabled
	isUploadActionEnabled?: boolean;
	// function that triggers the opening of the modal when upload clicked
	uploadActionHandler?: (params: string) => void
	// boolean value checking if generate PDF file action is enabled
	isGeneratePDFButtonEnabled?: boolean;
	// function to generate a PDF file
	generatePDFButtonHandler?: (param?: string) => void;
	// boolean value checks if button "Dodaj" is enabled
	isAddButtonEnabled?: boolean;
	// boolean value checking if checkbox is enabled
	isCheckboxEnabled?: boolean;
	// boolean value checking if all checkboxes are selected
	isAllSelectedEnabled?: boolean;
	// background is white for "true" value, otherwise background is gray
	isWhiteArea?: boolean
	// additional JSX Element added to Search Bar
	additionalResourceElement?: JSX.Element
	getSelectedCheckboxes?: (value: string[]) => void
	// checks is button was disabled
	isButtonDisabled?: boolean
	// checks if additional cell is needed
	isAdditionalCellNeeded?: boolean
	// array of selected checkboxes to generate pdf
	selectedCheckboxes?: string[]
	// boolean value checking if audit on site is enabled
	clientId?: string
	isActionList?: boolean
	isAuditOnlineEnabled?: boolean
	openAuditOnlineModalHandler?: () => void
}

export const CustomTable: FC<IProps> = observer(({
	                                                 data,
	                                                 isDataDownloaded,
	                                                 headCells,
	                                                 customTableConfig,
	                                                 addModalHandler,
	                                                 isActionsEnabled,
	                                                 isViewActionEnabled,
	                                                 viewActionAccess,
	                                                 viewActionHandler,
	                                                 isEditActionEnabled,
	                                                 archiveActionHandler,
	                                                 isArchiveActionEnabled,
	                                                 editActionAccess,
	                                                 editActionHandler,
	                                                 isDeleteActionEnabled,
	                                                 deleteActionAccess,
	                                                 isCopyActionEnabled,
	                                                 copyActionAccess,
	                                                 copyActionHandler,
	                                                 isDownloadActionEnabled,
	                                                 downloadActionAccess,
	                                                 downloadActionHandler,
	                                                 isUploadActionEnabled,
	                                                 uploadActionHandler,
	                                                 isGeneratePDFButtonEnabled,
	                                                 generatePDFButtonHandler,
	                                                 isAddButtonEnabled,
	                                                 isCheckboxEnabled,
	                                                 isWhiteArea,
	                                                 isAllSelectedEnabled,
	                                                 additionalResourceElement,
	                                                 deleteActionHandler,
	                                                 getSelectedCheckboxes,
	                                                 isButtonDisabled,
	                                                 isAdditionalCellNeeded,
	                                                 selectedCheckboxes,
	                                                 clientId,
	                                                 isActionList,
	                                                 isAuditOnlineEnabled,
	                                                 openAuditOnlineModalHandler
                                                 }): JSX.Element | null => {

	const {
		tableConfig,
		filterChangeHandler,
		changePageHandler,
		changeRowPerPageHandler,
		setSelectedHandler,
		allSelectToggler,
		isAllSelected,
		isSelectedHandler,
		createSortHandler,
		drawArray
	} = useTableController(headCells, data, customTableConfig, getSelectedCheckboxes)

	const dancePadding = isCheckboxEnabled ? '8px 16px' : '16px';

	return (
		<Grid
			container
		>
			<SearchBar
				filterChangeHandler={filterChangeHandler}
				filterValue={tableConfig.searchValue}
				isAddButtonEnabled={isAddButtonEnabled}
				addModalHandler={addModalHandler}
				isGeneratePDFButtonEnabled={isGeneratePDFButtonEnabled}
				isGeneratePDFButtonDisabled={selectedCheckboxes && selectedCheckboxes.length === 0}
				generatePDFButtonHandler={generatePDFButtonHandler}
				isWhiteArea={isWhiteArea}
				additionalResourceElement={additionalResourceElement}
				isButtonDisabled={isButtonDisabled}
				isAuditOnlineEnabled={isAuditOnlineEnabled}
				openAuditOnlineModalHandler={openAuditOnlineModalHandler}
			/>
			{isDataDownloaded ? (
					<>
						<TableContainer>
							<Table
								sx={{
									minWidth: '650px',
									backgroundColor: 'white',
								}}
							>
								<TableHead>
									<TableRow>
										{
											isCheckboxEnabled && !isAllSelectedEnabled && <TableCell/>
										}
										{
											isCheckboxEnabled && isActionsEnabled && !isAdditionalCellNeeded && <TableCell/>
										}
										{
											isAllSelectedEnabled && (
												<CheckBoxCell
													dataId={`all${new Date().getTime()}`}
													isSelected={isAllSelected}
													onChange={() => allSelectToggler(data.map(item => item.id as string))}
												/>
											)
										}
										{
											headCells.map((headCell) => (
												<TableCell
													key={headCell.id}
													align={headCell.numeric ? 'right' : 'left'}
													sortDirection={tableConfig.order === headCell.id ? tableConfig.order : false}
													sx={{
														padding: dancePadding,
														borderRight: headCells.length > 1 ? '1px solid #E0E0E0' : 'none',
													}}
												>
													<TableSortLabel
														active={tableConfig.orderBy === headCell.id}
														direction={tableConfig.orderBy === headCell.id ? tableConfig.order : TableSortOrder.Ascending}
														onClick={() => createSortHandler(headCell.id)}
													>
														{headCell.label}
														{
															tableConfig.orderBy === headCell.id ? (
																	<Box component="span" sx={visuallyHidden}>
																		{tableConfig.order === "desc" ? "sorted descending" : "sorted ascending"}
																	</Box>
																)
																: null
														}
													</TableSortLabel>
												</TableCell>
											))
										}
										{
											isActionsEnabled && (
												<TableCell
													align='center'
													sx={{
														padding: dancePadding,
														borderRight: 'none',
													}}
												>
													Akcje
												</TableCell>
											)
										}
									</TableRow>
								</TableHead>
								<TableBody>
									{
										drawArray.slice(tableConfig.page * tableConfig.rowsPerPage, tableConfig.page * tableConfig.rowsPerPage + tableConfig.rowsPerPage).map(row => (
											<TableRow key={row.id}>
												{
													isCheckboxEnabled && (
														<CheckBoxCell
															dataId={row.id}
															isSelected={isSelectedHandler(row.id as string)}
															onChange={() => setSelectedHandler(row.id as string)}
														/>
													)
												}
												{
													Object.keys(row).map((key, index) =>
														<CellsSwitcher
															key={row[key].toString() + index.toString()}
															keyName={key.toString()}
															value={row[key]}
															articleId={clientId && row.id.toString()}
															actionId={row.id.toString()}
															clientId={clientId}
															isActionList={isActionList}
															onClick={() => {
																viewActionHandler && viewActionHandler(row.id.toString(), row.name?.toString())
															}}
															dancePadding={dancePadding}
														/>
													)
												}
												{
													isActionsEnabled && (
														<TableCell
															sx={{
																padding: dancePadding,
															}}
														>
															<TableActions
																isViewActionEnabled={isViewActionEnabled}
																viewActionAccess={viewActionAccess}
																isEditActionEnabled={isEditActionEnabled}
																//viewActionHandler={() => viewActionHandler && viewActionHandler(row.id.toString())}
																editActionAccess={editActionAccess}
																editActionHandler={() => editActionHandler && editActionHandler(row.id.toString())}
																archiveActionHandler={() => archiveActionHandler && archiveActionHandler(row.id.toString(), row.name?.toString())}
																deleteActionHandler={() => deleteActionHandler && deleteActionHandler(row.id.toString(), row.name?.toString() || row.title?.toString())}
																isDeleteActionEnabled={isDeleteActionEnabled}
																isUploadActionEnabled={isUploadActionEnabled}
																deleteActionAccess={deleteActionAccess}
																isCopyActionEnabled={isCopyActionEnabled}
																copyActionAccess={copyActionAccess}
																copyActionHandler={() => copyActionHandler && copyActionHandler(row.id.toString())}
																isDownloadActionEnabled={isDownloadActionEnabled}
																isArchiveActionEnabled={isArchiveActionEnabled}
																downloadActionAccess={downloadActionAccess}
																downloadActionHandler={() => downloadActionHandler && downloadActionHandler(row.id.toString(), row.title.toString())}
																uploadActionHandler={() => uploadActionHandler && uploadActionHandler(row.id.toString())}
															/>
														</TableCell>
													)
												}
											</TableRow>
										))
									}
								</TableBody>
							</Table>
						</TableContainer>
						<Grid
							container
							maxHeight='52px'
							justifyContent='flex-end'
						>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component='div'
								count={drawArray.length}
								rowsPerPage={tableConfig.rowsPerPage}
								page={tableConfig.page}
								onPageChange={changePageHandler}
								onRowsPerPageChange={changeRowPerPageHandler}
								sx={{bgColor: 'black'}}
							/>
						</Grid>

					</>
				) :
				<Typography
					variant='body1'
					mb={2}
				>
					Brak danych do wyświetlenia
				</Typography>
			}
		</Grid>
	)
})
