import React from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import { NavBarHeader } from "./NavBarHeader";
import { useNavBarViewModel } from "./useNavBar.vm";
import { MenuList } from "./MenuList";

export const NavBar = observer(() => {
  const {isNavbarOpen, toggleNavBarHandler, links, clientName, isClient} = useNavBarViewModel()

  return (
    <Grid
      item
      style={{
        width: '240px',
        minWidth: '240px',
        padding: '8px',
        backgroundColor: '#757575',
        marginLeft: isNavbarOpen ? '0px' : '-182px',
        transition: 'margin-left 0.5s ease-in-out',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
      }}
    >
      <NavBarHeader isNavbarOpen={isNavbarOpen} toggleNavBarHandler={toggleNavBarHandler}
                    title={!isClient ? clientName : ''}/>
      <MenuList linkList={links} isNavbarOpen={isNavbarOpen}/>
    </Grid>
  )
})