import { useEffect } from "react";
import { useAuditOnsiteApi } from "../../../../apiHooks";
import { useAuditOnsiteStore, useCurrentClientStore } from "../../../../store/hooks";

export const useAuditOnSiteViewModel = () => {
  const {getAudit} = useAuditOnsiteApi();
  const currentClientStore = useCurrentClientStore();
  const auditsOnSiteStore = useAuditOnsiteStore();
  const {auditOnSite} = auditsOnSiteStore;

  useEffect(() => {
    void getAudit();

    return () => {
      currentClientStore.clearCurrentClientStore();
      auditsOnSiteStore.clearAuditOnSite();
    }
  }, [getAudit, currentClientStore, auditsOnSiteStore]);

  const isDataDownloaded = !!auditOnSite;

  return {
    isDataDownloaded,
  }
}