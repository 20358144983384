import {ILink} from "../../../../../interfaces";

export const additionAdminLinks = (clientId: string): ILink[] => [
  {
    title: 'Biblioteka ',
    link: `client/${clientId}/library`,
    ico: 'MenuBookRounded'
  },
  {
    title: 'Audyty aktualne',
    link: `client/${clientId}/audits-onsite`,
    ico: 'PinDropRounded',
  },
  {
    title: 'Action list',
    link: `client/${clientId}/action-list`,
    ico: 'PlaylistAddCheckCircleRounded'
  },
  {
    title: 'Pracownicy',
    link: `client/${clientId}/employees`,
    ico: 'PeopleOutlineRounded'
  },
  {
    title: 'Kontrola i zapisy',
    link: `client/${clientId}/controls/check-lists`,
    ico: 'PlaylistAddCheckCircleRounded'
  },
  {
    title: 'Wybór klienta',
    link: '/clients',
    ico: 'OtherHousesRounded'
  },
]