import { makeAutoObservable } from 'mobx';

import { RootStore } from "./rootStore";
import { IUserInfo } from "../interfaces";
import { ProjectUserRoles } from "../constants";

export class AuthStore {
  isUserLoad = false;
  isLoggedIn = false;
  role: ProjectUserRoles = ProjectUserRoles.ROLE_GUEST;
  email: string = '';
  name: string = '';
  navigationLink: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  appLogin(response: {isAuth: boolean, userInfo: IUserInfo}) {
    this.isLoggedIn = response.isAuth;
    this.role = response.isAuth && response.userInfo.role !== ProjectUserRoles.ROLE_EMPLOYEE ? response.userInfo.role : ProjectUserRoles.ROLE_GUEST;
    this.email = response.isAuth ? response.userInfo.email : '';
    this.name = response.isAuth ? response.userInfo.name : '';
    this.setUserLoad();
    return this.role;
  }

  appLogout() {
    this.email = '';
    this.name = '';
    this.isLoggedIn = false;
    this.role = ProjectUserRoles.ROLE_GUEST;
  }

  saveNavigationLink(navigationLink: string) {
    this.navigationLink = navigationLink;
  }

  setUserLoad() {
    this.isUserLoad = true;
    this.rootStore.loadersStore.unsetGlobalLoader();
  }
}
