import { useRootStore } from "../RootStateContext";

export const useResourcesStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.resourcesStore) {
        throw new Error('resourcesStore store should be defined');
    }

    return rootStore.resourcesStore;
}