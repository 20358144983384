import React from "react";
import { observer } from "mobx-react-lite"
import { useAuthStore, useWindowDimensionsStore } from "../../../store/hooks";
import { Typography } from "@mui/material";
import { ModalContainer } from "../../shared/modals/modalContainer/ModalContainer";
import { ProjectUserRoles } from "../../../constants";

export const ContentCover = observer(() => {
	const windowDimensionsStore = useWindowDimensionsStore();
	const authStore = useAuthStore();
	const {role} = authStore;

	const {width, height} = windowDimensionsStore;

	return (width < 768 || height < 600) && role !== ProjectUserRoles.ROLE_GUEST
		?
		(
			<ModalContainer>
				<Typography variant='h4' padding={5} textAlign='center'>
					Sorry, but this webpage does not work with screen width less than 780px and height less then 600px.
				</Typography>
			</ModalContainer>
		)
		:
		null
})