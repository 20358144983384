import React from "react";

import './IconButton.scss';

interface IProps {
	onClick: () => void;
	children: React.ReactNode;
	type?: 'button' | 'submit' | 'reset' | undefined;
	style?: React.CSSProperties;
}

export const IconButton: React.FC<IProps> = ({onClick, children, style, type}) => {

	return (
		<button
			className='iconButton'
			onClick={onClick}
			style={style}
			type={type ? type : 'button'}
		>
			{children}
		</button>
	)
}