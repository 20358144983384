export enum ProjectChecklistTypes {
// Karta kontroli dostaw towarów
  CCP1 = 'CCP1',
// Karta kontroli temperatur w urządzeniach do magazynowania
  CCP2 = 'CCP2',
// Karta rejestru obróbki termicznej
  CCP3 = 'CCP3',
// Karta schładzania potraw
  CCP4 = 'CCP4',
// Karta kontroli temperatury i jakości tłuszczu smażalniczego
  CCP5 = 'CCP5',
// Karta mycia i dezynfekcji jaj
  CCP6 = 'CCP6',
// Karta maszyny/urządzenia
  CCP7 = 'CCP7',
// Karta urządzenia do pomiarowania (termometr)
  CCP8 = 'CCP8',
// Karta kontroli temperatury i wilgotności w magazynie spożywczym suchym
  CCP9 = 'CCP9',
// Formularz kontroli stanu higieny personelu
  CCP10 = 'CCP10',
// Karta higieny kuchennej
  CCP11 = 'CCP11',
// Karta niezgodności	
  CCP12 = 'CCP12',
}