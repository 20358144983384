import {CSSProperties} from "react";
import * as icons from "@mui/icons-material";

export type IconNames = keyof typeof icons // use this in other components

interface IGenericIconProps {
  iconName: IconNames;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
  style?: CSSProperties;
}

export const GenericIcon = ({iconName, style, fontSize}: IGenericIconProps): JSX.Element => {
  const Icon = icons[iconName];
  return <Icon fontSize={fontSize || 'small'} sx={style}/>;
};