import { Typography } from "@mui/material";

import React, { FC } from "react";
import { ModalContainer } from "../../../shared/modals/modalContainer/ModalContainer";
import { observer } from "mobx-react-lite";
import { useReportsModalViewModel } from "./useReportsModal.vm";
import { RadioButtonsGroup } from "./radioButtonsGroup/RadioButtonsGroup";
import { ReportsTable } from "./reportsTable/ReportsTable";

interface IProps {
  closeModalHandler: () => void,
  reportId?: string;
  itemName?: string;
  isModalOpen: boolean;
}

export const ReportsModal: FC<IProps> = observer(({
                                                    closeModalHandler,
                                                    reportId,
                                                    isModalOpen,
                                                  }) => {

  const {
    confirmHandler,
    reportInfo,
    selectedValue,
    handleChange,
    startDateValue,
    endDateValue,
    handleStartDate,
    handleEndDate,
    isAuditor,
    generatePDF
  } = useReportsModalViewModel(reportId, closeModalHandler);

  return (
    <ModalContainer
      backDrop={true}
      handleClose={closeModalHandler}
      isModalOpen={isModalOpen}
      style={{
        width: '100%',
        minWidth: '650px',
      }}
      acceptButtonHandler={confirmHandler}
      acceptButtonTitle={isAuditor ? 'zatwierdź' : undefined}
      deleteButtonTitle={true}
      oneButtonTitle={!isAuditor ? 'Zamknij' : undefined}
    >
      {reportInfo && <>
        <Typography
          variant='h6'
          mb={2}
        >
          {reportInfo.code} {reportInfo.name}
        </Typography>
        <Typography variant='body2'>{reportInfo.instruction}</Typography>
      </>}
      <Typography
        variant='h6'
        mb={2}
      >
        Ustaw zakras dat
      </Typography>
      <RadioButtonsGroup selectedValue={selectedValue}
                         generatePDF={generatePDF}
                         handleChange={handleChange}
                         startDateValue={startDateValue}
                         endDateValue={endDateValue}
                         handleStartDate={handleStartDate}
                         handleEndDate={handleEndDate}/>
      <>
        <Typography
          variant='h6'
          mb={2}
        >
          podgląd checklisty
        </Typography>
        <ReportsTable/>
      </>
    </ModalContainer>
  )
})