import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApiCall } from "./apiCall/useApiCall";
import { useActionListStore, useCurrentClientStore, useNotificationsStore } from "../store/hooks";
import { IActionListInfo, ISingleActionInfo } from "../interfaces";
import { customDateFormat } from "../utils/customDateFormat";
import { NotificationType } from "../store/notificationsStore/notificationType";

export const useActionListApi = () => {
  const {api} = useApiCall();
  const params = useParams();
  const {clientId, actionId} = params;
  const currentClientStore = useCurrentClientStore();
  const actionListStore = useActionListStore();
  const notificationsStore = useNotificationsStore();

  const getActionList = useCallback(
    async () => {
      const res: {
        list: IActionListInfo[],
        clientName: string
      } = await api.apiInstance.get(clientId ? `client/${clientId}/action-lists` : `action-lists`, true);
      if (res) {
        if (clientId) {
          currentClientStore.setClientName(res.clientName, clientId);
        }

        actionListStore.setActionList(res.list.map(el => ({
          ...el,
          lastUpdateDate: customDateFormat(el.lastUpdateDate)
        })));
      }
    }, [api.apiInstance, clientId, currentClientStore, actionListStore])

  const getAction = useCallback(async () => {
    const res: ISingleActionInfo = await api.apiInstance.get(clientId ? `client/${clientId}/action-list/${actionId}` : `action-list/${actionId}`, true);

    if (res) {
      currentClientStore.setClientName(res.clientName ? res.clientName : 'brak danych', clientId);
      actionListStore.setAction(res)
    }
  }, [api.apiInstance, clientId, actionId, currentClientStore, actionListStore])

  const getSingleComment = useCallback(async (commentId: string) => {
    const res = await api.apiInstance.get(clientId ? `client/${clientId}/action-list/${actionId}/comment/${commentId}` : `action-list/${actionId}/comment/${commentId}`, true);

    if (res) {
      currentClientStore.setClientName(res.clientName ? res.clientName : 'brak danych', clientId);
      actionListStore.setSingleComment(res)
    }
  }, [api.apiInstance, clientId, actionId, currentClientStore, actionListStore])

  const addCorrectiveActionByClient = useCallback(async (commentId: number, data: { comment: string }) => {
    const res = await api.apiInstance.patch(`action-list/comment/${commentId}/corrective-actions`, data, true);

    if (res) {
      notificationsStore.setNotification({message: 'Komentarz został wysłany.', type: NotificationType.SUCCESS});
      if (actionListStore.singleComment) {
        actionListStore.setSingleComment({
          ...actionListStore.singleComment,
          clientCorrectiveActions: data.comment,
          clientCorrectiveActionsDate: res.lastUpdateDate
        })
      }
    }
  }, [actionListStore, api.apiInstance, notificationsStore])

  const addAssessmentOfCorrectiveActionByAuditor = useCallback(async (commentId: number, data: { comment: string }) => {
    const res = await api.apiInstance.patch(`action-list/comment/${commentId}/assessment-of-corrective-actions`, data, true);

    if (res) {
      notificationsStore.setNotification({message: 'Komentarz został wysłany.', type: NotificationType.SUCCESS});
      currentClientStore.setClientName(res.clientName ? res.clientName : 'brak danych', clientId);
      if (actionListStore.singleComment) {
        actionListStore.setSingleComment({
          ...actionListStore.singleComment,
          auditorCorrectiveActionsAssessment: data.comment,
          auditorCorrectiveActionsAssessmentDate: res.lastUpdateDate
        })
      }
    }
  }, [actionListStore, api.apiInstance, clientId, currentClientStore, notificationsStore])

  return {
    getActionList,
    getAction,
    getSingleComment,
    addCorrectiveActionByClient,
    addAssessmentOfCorrectiveActionByAuditor
  }
}