import { ILink } from "../../../../../interfaces";

export const controlsLinks = (clientId?: string): ILink[] => {
	return [
		{
			title: 'Checklisty ',
			link: clientId ? `client/${clientId}/controls/check-lists` : '/controls/check-lists',
			ico: 'MenuBookRounded'
		},
		{
			title: 'Raporty',
			link: clientId ? `client/${clientId}/controls/reports` : '/controls/reports',
			ico: 'FlakyRounded'
		},
		{
			title: 'Zasoby',
			link: clientId ? `client/${clientId}/controls/resources` : '/controls/resources',
			ico: 'PeopleOutlineRounded'
		},
	]
}