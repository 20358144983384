import { NotificationMessages, NotificationType } from "../../store/notificationsStore/notificationType";

export const notificationHandler = (status: number, message: string) => {
  
  switch (true) {
    case status >= 500:
      //default error for any code status  >= 500
      return {
        type: NotificationType.ERROR,
        message: NotificationMessages.DEFAULT_ERROR
      }
    case status === 403:
      //forbidden status when session is expired
      return {
        type: NotificationType.ERROR,
        message: NotificationMessages.SESSION_LOST
      }
    default:
      return {
        type: NotificationType.ERROR,
        message: message
      }
  }
}