import {makeAutoObservable} from "mobx";
import {IQuestion, IQuestionDetails} from "../interfaces/askExpert/askExpert";

export class AskExpertStore {
  questionDetails: IQuestionDetails | null = null
  questionList: IQuestion[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setQuestionList(questionList: IQuestion[]) {
    this.questionList = questionList
  }

  setQuestionDetails(questionDetails: IQuestionDetails) {
    this.questionDetails = questionDetails
  }

  get questionsDrawArray() {
    return this.questionList
  }

  get questionDetailsDrawArray() {
    return this.questionDetails
  }

}