import React from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import { CategoryItem } from "../categoryItem/CategoryItem";
import { getSingleCategoryArrayHandler } from "../../../helpers/getSingleCategoryArrayHandler";
import { useCategoryListViewModel } from "./useCategoryList.vm";

export const CategoryList = observer((): JSX.Element => {

  const {auditOnSiteFields} = useCategoryListViewModel();

  const categoriesTitleArray: string[] = [...new Set(auditOnSiteFields.map(el => el.category))]

  return (
    <Grid pt={2}>
      {categoriesTitleArray.map((el, index) => (
        <CategoryItem
          key={index}
          categoryName={el}
          categoriesCount={categoriesTitleArray.length}
          categoryIndex={index}
          categoryArray={getSingleCategoryArrayHandler(auditOnSiteFields, el)}
        />
      ))}
    </Grid>
  )
})