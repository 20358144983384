import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";

interface IProps {
  labelValue: 'yes' | 'no' | 'n/a';
}

export const ValueSwitcher: FC<IProps> = ({
                                            labelValue
                                          }): JSX.Element => {

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
    >
      <Typography variant='body1' component='p' style={{...styles, backgroundColor: labelValue === 'yes' ? 'rgba(0, 0, 0, .12)' : 'transparent'}}>TAK</Typography>
      <Typography variant='body1' component='p' style={{...styles, backgroundColor: labelValue === 'no' ? 'rgba(0, 0, 0, .12)' : 'transparent'}}>NIE</Typography>
      <Typography variant='body1' component='p' style={{...styles, backgroundColor: labelValue === 'n/a' ? 'rgba(0, 0, 0, .12)' : 'transparent'}}>NIE DOTYCZY</Typography>
    </Grid>
  )
}

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '120px',
  height: '36px',
  marginRight: '16px',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  color: 'rgba(0, 0, 0, .6)',
  fontSize: '14px',
  fontWeight: 500,
}