import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../store/RootStateContext';
import { AppLayout } from './appLayer/AppLayout';

export const App = observer(() => {
  const rootStore = useRootStore();
  const isStoreReady = !!rootStore;

  return isStoreReady ? <AppLayout /> : null;
});