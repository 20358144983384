import { makeAutoObservable } from "mobx";

export class CurrentClientStore {
  clientId: string | undefined = '';
  clientName: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setClientName(clientName: string, clientId?: string) {
    this.clientName = clientName;
    this.clientId = clientId;
  }

  clearCurrentClientStore() {
    this.clientName = '';
    this.clientId = '';
  }
}