import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow,

} from '@mui/material';

import {useReportsTable} from "./useReportsTable.vm";
import {CellsModifyer} from "./CellsModifyer";
import {observer} from "mobx-react-lite";

export const ReportsTable = observer(() => {
  const {changePageHandler, tableConfig, tableDataRows, changeRowPerPageHandler, headCells} = useReportsTable()

  return (
    <>
      <TableContainer>
        <Table sx={{
          minWidth: '650px',
          backgroundColor: 'white',
        }}>
          <TableHead>
            <TableRow>
              {
                headCells && headCells.map((headCell, index) => (
                  <TableCell
                    key={headCell.name}
                    sx={{
                      padding: '16px',
                      textAlign: 'center',
                      borderRight: headCells.length > 1 ? '1px solid #E0E0E0' : 'none',
                    }}
                  >
                    {headCell.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tableDataRows && tableDataRows.length > 0 && tableDataRows.slice(tableConfig.page * tableConfig.rowsPerPage, tableConfig.page * tableConfig.rowsPerPage + tableConfig.rowsPerPage).map((row, index) => (
                <TableRow key={index.toString()}>
                  {
                    Object.keys(row).map((key, index) =>
                      <CellsModifyer
                        key={row[key].toString() + index.toString()}
                        keyName={key.toString()}
                        value={row[key]}
                      />
                    )
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        maxHeight='52px'
        justifyContent='flex-end'
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={tableDataRows ? tableDataRows.length : 0}
          rowsPerPage={tableConfig.rowsPerPage}
          page={tableConfig.page}
          onPageChange={changePageHandler}
          onRowsPerPageChange={changeRowPerPageHandler}
        />
      </Grid>
    </>
  )
})