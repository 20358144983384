import { useRootStore } from "../RootStateContext";

export const useAskExpertStore = () => {
    const rootStore = useRootStore();

    if (!rootStore?.askExpertStore) {
        throw new Error('askExpertStore store should be defined');
    }

    return rootStore.askExpertStore;
}