import {useAskExpertStore, useAuthStore} from "../../../store/hooks";
import {useAskExpertApi} from "../../../apiHooks";
import {IHeadCell} from "../../../interfaces";
import {createTableRows} from "../../shared/customTables/utils/createTableRows";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ProjectUserRoles} from "../../../constants";
import {IQuestion} from "../../../interfaces/askExpert/askExpert";

export const useAskExpertViewModel = (closeModalHandler?: () => void, questionId?: string) => {
  const navigate = useNavigate();
  const {role} = useAuthStore();
  const askExpertStore = useAskExpertStore();
  const questions = askExpertStore.questionsDrawArray;
  const {getQuestions} = useAskExpertApi(closeModalHandler);

  const headCells: IHeadCell[] = [
    {id: 'number', numeric: false, label: 'Nr. zapytania'},
    {id: 'title', numeric: false, label: 'Temat'},
    {id: 'createdAt', numeric: false, label: 'Utworzenie'},
    {id: 'updatedAt', numeric: false, label: 'Modyfikacja'},
  ]

  const tableDataRows = createTableRows(questions, headCells, false)

  const itemName = questions.length > 0 ? questions.find((el: IQuestion) => el.id === questionId)?.title : ''

  useEffect(() => {
    void getQuestions()
  }, [getQuestions])

  const viewActionHandler = (id: string, name: string) => {
    navigate(`/ask-expert/${id}`)
  }

  const isDataDownloaded = questions !== null;

  return {
    isClient: ProjectUserRoles.ROLE_CLIENT === role,
    tableDataRows,
    isDataDownloaded,
    viewActionHandler,
    headCells,
    itemName
  }
}